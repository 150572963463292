#tips {
    padding: 100px 0; }

.mini-title {
    h3 {
        font-size: 25px;
        font-weight: 600;
        line-height: 35px;
        color: #dedede;
        margin-bottom: 15px;

        span {
            color: $color_theme-2; } } }


.tips-content {
    .popup-btn {
        visibility: visible !important;
        opacity: 1;
        padding: 10px 11px;
        font-weight: 600;
        border-radius: 4px;
        font-size: 16px;

        i {
            background: transparent;
            color: #fff;
            font-size: 20px;
            margin-right: 2px; } }

    p {

        font-size: 18px;
        line-height: 28px; } }

.game-video-item {
    &.game-box-video {
        background-repeat: no-repeat;
        .feature-image {
            padding: 50px 80px 50px 50px; }

        .youtube-wrapper {
            padding-bottom: 59.7%;
            border-radius: 10px;

            &:before {
                content: '';
                background-image: url("../../media/video/play.png");
                height: 80px;
                width: 80px; }

            .youtube-poster {
                height: 245px; } } } }




@media (max-width: 1199px) {

    .mini-title {
        h3 {
            font-size: 22px; } }

    .tips-content {
        p {
            font-size: 16px; } }

    .game-video-item {
        &.game-box-video {
            background-size: cover;

            .feature-image {
                padding: 28px 42px 93px 56px; } } } }

@media (max-width: 768px) {
    #tips {
        padding: 50px 0; }

    .game-video-item {
        &.game-box-video {
            margin-top: 20px; } } }

@media (max-width: 768px) {
    .game-video-item {
        &.game-box-video {

            .feature-image {
                padding: 28px 5% 93px 13%; } } } }
