#countup {
    background: #1a1c20; }

.counter-box {
    .count {
        .counter {
            margin: 0 auto;
            margin-bottom: 5px;
            background-repeat: no-repeat;

            span {
                display: inline-block;
                color: #fff;
                font-weight: 700;
                font-size: 36px; } }
        img {
            margin-bottom: 10px; }

        h3 {
            font-size: 20px;
            font-weight: 500;
            color: #cccccc;
            line-height: 25px; } } }

@media (max-width: 991px) {
    .counter-box {
        margin-bottom: 30px; } }
