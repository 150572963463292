#torurmant-count, #torurmant-count-two, #torurmant-count-three {
    // background: #141416
    background: #13131f;
    margin-top: -1px; }

.torurmant {
    padding: 60px 50px;
    text-align: center;
    position: relative;
    background: #21212d;
    bckground: #1a1c20;

    .link-btn {
        position: absolute;
        top: 25px;
        right: 30px;
        color: $color_theme-2;
        font-weight: 700;
        font-size: 16px; } }

.torurmant-title {
    background-image:  $color_gradian;
    margin-bottom: 20px;

    h3 {
        color: #fff;
        font-size: 20px;
        line-height: 20px;
        margin: 0;
        background: url(../../media/background/stripe.png) top left;
        padding: 20px; } }

.torurmant-game {
    width: 33.33%;
    float: left;

    img {
        height: 120px;
        width: 120px;
        border-radius: 50%; }

    h3 {
        font-size: 25px;
        color: $color_theme-2;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 0; }

    .tim-btn {
        border: 0;
        border-radius: 0;
        font-size: 20px;
        font-weight: 700;
        padding: 17px 55px;
        border: 1px solid #fff;

        &:hover {
            border-color: $color_theme-2; } } }

.tags {
    position: absolute;
    right: 30px;
    bottom: 20px;
    a {
        background: #131313;
        font-size: 14px;
        color: #fff;
        padding: 0 15px;
        display: inline-block;
        margin-right: 3px;
        line-height: 25px;

        &:hover {
            color: $color_theme-2; } } }

.countdown {
    margin-bottom: 50px;

    .CountdownContent {
        height: 50px;
        width: 50px;
        background: #262636;
        color: $color_theme-2;
        font-size: 25px;
        display: inline-block;
        text-align: center;
        margin-right: 2px;
        font-weight: 600;
        line-height: 55px; } }

.vs-fire {
    height: 50px;
    width: 50px;
    font-size: 25px;
    line-height: 55px;
    font-weight: 600;
    color: #fff;
    background: $color_theme-2;
    margin: 0 auto 50px;
    border-radius: 50%;
    position: relative;

    canvas {
        position: absolute;
        left: 0;
        border-radius: 50%; }



    &:before, &:after {
        position: absolute;
        content: '';
        border: 1px solid $color_theme-2;
        border-radius: 50%; }


    &:before {
        top: -10px;
        bottom: -10px;
        right: -10px;
        left: -10px;
        animation: opacity 1000ms infinite ease-in-out;
        animation-delay: 800ms; }


    &:after {
        top: -20px;
        bottom: -20px;
        right: -20px;
        left: -20px;
        animation: opacity 1000ms infinite ease-in-out; } }



@keyframes opacity {
    0% {
        opacity: 1; }

    50% {
        opacity: .2; }

    100% {
        opacity: 1; } }


.latest-game {
    padding: 40px;
    // background: #1a1c20
    background: #21212d;

    h3 {
        font-size: 30px;
        font-weight: 700;
        line-height: 30px;
        margin-bottom: 20px;
        color: #e9e9e9;

        a {
            color: $color_theme-2;

            &:hover {
                color: $color_theme; } } }

    .tim-btm-bg {
        font-size: 20px;
        font-weight: 700;
        padding: 16px 67px;
        border: 0;
        border-radius: 0;
        color: #fff;
        margin: 40px  auto 10px;
        border: 1px solid #fff;

        &:hover {
            background: $color_theme-2;
            border-color: $color_theme-2; } }

    .game-item {
        padding: 0 5px; }

    img {
        width: 100%;
        transform: skew(-5deg); }

    .game-name {
        display: inline-block; }

    h4 {
        font-size: 20px;
        line-height: 20px;
        font-weight: 700;
        margin-bottom: 0;
        margin-top: 15px;

        a {
            color: #fff;

            &:hover {
                color: $color_theme-2; } } }

    .review {
        li {
            font-size: 14px;
            color: $color_theme-2; } }

    .ratting-point {
        float: right;
        height: 30px;
        width: 30px;
        background: $color_theme-2;
        border-radius: 50%;
        line-height: 30px;
        text-align: center;
        margin-top: 15px;
        margin-right: 20px;

        p {
            font-weight: 600;
            color: #fff;
            margin: 0;
            font-size: 14px; } } }

.game-tabs {
    // background: #1a1c20
    background: #21212d; }

.tim-tab-nav-game {
    border-bottom: 1px solid #313131;

    li {
        text-align: center;
        border-right: 1px solid #313131;

        &:last-child {
            border-right: 0; }

        a {
            text-transform: uppercase;
            color: #e9e9e9;
            font-size: 15px;
            line-height: 25px;
            font-weight: 600;
            padding: 15px 13px;
            display: block;

            &.active {
                // background: rgba(0,0,0,0.2)
                color: $color_theme-2; } } } }

.letest-games {
    padding: 24px 20px;
    border-bottom: 1px solid #2d2d2d;

    &:last-child {
        border-bottom: 0; }
    a {
        display: inline-block;
        height: 60px;
        width: 60px;

        img {
            width: 100%;
            border-radius: 50%; } }

    .game-time {
        display: inline-block;
        text-align: center;
        vertical-align: middle;

        .time {
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 600;
            line-height: 20px;
            color: #fff; }

        .date {
            color: #fff;
            font-size: 16px;
            margin: 0; } }

    span {
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        margin: 0 15px;
        display: inline-block; } }

@media (max-width: 992px) {
    .game-wraper-tab {
        margin-top: 20px; } }


@media (min-width: 991px) and (max-width: 1200px) {
    .tim-tab-nav-game {
        li {
            a {
                font-size: 13px;
                padding: 9px; } } }

    .letest-games {
        padding: 15px;
        text-align: center;

        span {
            margin: 0 10px;

            &.eql {
                display: none; } }

        .game-time {
            margin-top: 20px;

            p {
                display: inline-block; } } }

    .countdown {
        .CountdownContent {
            width: 40px;
            height: 40px;
            line-height: 43px;
            font-size: 20px; } }

    .torurmant-game {
        .tim-btn-bg {
            padding: 17px 35px; } }

    .torurmant {
        padding: 60px 30px; } }

@media (max-width: 991px) {
    .torurmant {
        padding: 50px 25px; }

    .latest-game {
        background-size: cover;
        background-position: center center;
        .game-item {
            max-width: 300px;
            margin: 0 auto 30px; } }

    .game-tabs {
        max-width: 450px;
        margin: 0 auto; }

    .tim-tab-nav-game {
        li {
            a {
                padding: 20px 23px; } } } }

@media (max-width: 768px) {

    .torurmant-game {
        width: 25%;

        &:nth-child(3) {
            width: 50%; }

        img {
            width: 90px;
            height: 90px; } }

    .countdown {
        margin-bottom: 30px;
        .CountdownContent {
            height: 40px;
            width: 40px;
            line-height: 44px;
            font-size: 20px; } }

    .torurmant-game {
        h3 {
            font-size: 20px; }
        .tim-btn-bg {
            font-size: 16px;
            padding: 13px 37px; } }

    .letest-games {
        span {
            margin: 0 30px; } } }



@media (max-width: 500px) {

    .torurmant-game {
        width: 100%;

        &:nth-child(3) {
            width: 100%;
            margin: 30px 0 20px; } }

    .letest-games {
        text-align: center; }

    .tim-tab-nav-game {
        text-align: center;
        li {
            width: 28%;

            &:first-child {
                width: 15%; }
            a {
                padding: 10px 5px;
                font-size: 16px; } } }

    .letest-games {
        span {
            margin: 0 20px; } }

    .letest-games {
        padding: 22px 10px; } }
