#feature-game {
    position: relative;
    background: #13131f;

    .container {
        position: relative; } }

#feature-game-two {
    position: relative;

    .container {
        position: relative; } }

.slider-nav-prev,
.slider-nav-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 40px;
    width: 40px;
    transition: all 0.3s ease-in-out;
    text-align: center;
    line-height: 45px;
    cursor: pointer;

    i {
        color: #fff;
        font-size: 20px;
        z-index: 2; }

    &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        height: 40px;
        width: 40px;
        background: $color_theme-2;
        z-index: -1;
        transform: rotate(-45deg); }

    &:hover {
        opacity: 0.8; } }

.slider-nav-prev {
    left: -5%; }

.slider-nav-next {
    right: -5%; }

.game-feature-slider {
    padding: 0 15px;

    .game-items {
        transform: skew(-8deg);

        img {
            max-width: 100%;
            z-index: -1; }

        .item {
            position: static;
            .game-content {
                width: 100%;
                transform: skew(8deg);
                z-index: 111;

                h3 {

                    line-height: 20px;
                    margin-bottom: 0;
                    a {
                        font-size: 20px;
                        line-height: 20px;
                        font-weight: 700;

                        &:hover {
                            color: $color_theme-2; } } }

                .review {
                    li {
                        font-size: 13px;
                        line-height: 13px;
                        color: $color_theme-4; } }
                .meta {
                    li {
                        margin-right: 0;

                        a {
                            &:hover {
                                color: $color_theme-2; } } } }

                .details-btn {
                    position: absolute;
                    right: 50px;
                    bottom: 30px; } }

            &:hover {
                img {
                    transform: scale(1.02);
                    backface-visibility: hidden; } } } } }


@media (max-width: 991px) {
    .game-feature-slider {
        .game-items {
            .item {
                .game-content {
                    .details-btn {
                        right: 28px; }
                    h3 {
                        a {
                            font-size: 18px; } } } } } }

    .meta {
        li {
            a {
                font-size: 14px; } } } }


@media (min-width: 768px) and (max-width: 1024px) {
    #feature-game-nav-prev, #feature-game-nav-next {
        display: none; } }

@media (max-width: 767px) {
    #feature-game-nav-prev, #feature-game-nav-next {
        height: 30px;
        width: 30px;
        line-height: 35px;
        display: none;

        &:before {
            height: 30px;
            width: 30px; } } }

@media (max-width: 500px) {

    .slider-nav-prev, .slider-nav-next {
        display: none; }

    .game-feature-slider {
        .game-items {
            max-width: 270px;
            margin: 0 auto; } } }


