.widget {
    margin-bottom: 30px; }
.widget-title {
    color: #dedede;
    font-size: 20px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #685858; }

//Search
.search-form, .search-form-product {
    position: relative;

    label {
        color: #333;
        display: block;
        font-weight: 800;
        margin-bottom: 0.5em; }

    input[type="search"] {
        border: 2px solid #a1aeaf;
        display: block;
        padding: 10px 30px 10px 15px;
        width: 100%;
        outline: 0;
        border-radius: 10px;
        height: 45px;
        background: rgba(255, 255, 255, 0.102);
        color: #dedede;

        &::placeholder {
            color: #dedede; } }


    .search-submit {
        position: absolute;
        right: 10px;
        top: 50%;
        border: 0;
        background: transparent;
        color: #dedede;
        outline: 0;
        transform: translateY(-50%);
        font-size: 14px; }

    .screen-reader-text {
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        overflow: hidden;
        position: absolute !important;
        width: 1px;
        word-wrap: normal !important; } }

// Social Link
.widget-social-link {
    margin: 0;
    list-style: none;
    padding: 25px;
    background: #252525;
    border-radius: 10px;

    li {
        display: inline-block;
        width: 18%;
        text-align: center;
        margin-bottom: 20px;

        .fa.fa-facebook {
            color: #3a5897; }

        .fa-twitter {
            color: #49c9f2; }

        .fa-instagram {
            color: #3b749f; }

        .fa-pinterest-p {
            color: #cd2129; }

        .fa-linkedin {
            color: #07669f; }

        .fa-behance {
            color: #0683f0; }

        .fa-google-plus {
            color: #d13e2c; }

        .fa-rss {
            color: #f88a02; }

        .fa-tumblr {
            color: #274152; }

        .fa-youtube {
            color: #ce332c; }

        .fa-vimeo {
            color: #85c8ee; }

        .fa-cloud {
            color: #c1c1c1; }

        .fa-stumbleupon {
            color: #399734; }

        .fa-snapchat-ghost {
            color: #ff6600; }

        .fa-dribbble {
            color: #ea4b88; }



        a {
            display: block;
            font-size: 20px;
            color: #fff;

            &:hover {
                opacity: 0.8; } }

        &:nth-last-child(-n+1) {}
        &:nth-last-child(-n+2) {}
        &:nth-last-child(-n+3) {}
        &:nth-last-child(-n+4) {}
        &:nth-last-child(-n+5) {
            margin-bottom: 0; } } }

//Latest Post
.widgets-recent-post {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        display: block;
        margin-bottom: 20px;

        .feature-img {
            float: left;
            border-radius: 10px;
            overflow: hidden;
            transition: all 0.3s ease-in-out;

            &:hover {
                opacity: 0.8; } }

        .content {
            margin-left: 80px;
            padding: 5px 0;

            h3 {
                font-size: 16px;
                margin: 0 0 5px;
                a {
                    font-weight: 500;
                    color: #dedede;

                    &:hover {
                        color: $color_theme-4; } } }

            .post-date {
                color: #808080;

                i {
                    margin-right: 5px; } } } } }

// Tag
.tags-links {
    a {
        display: inline-block;
        color: #bcbcbc;
        font-size: 16px;
        line-height: 20px;
        margin-right: 20px;
        font-weight: 500;

        &:hover {
            color: $color_theme-4; } } }

// Upcoming Game
.widget_upcoming_game_entries {}


.widget_upcoming_game {

    margin: 0;
    padding: 0;

    > li {
        display: block;
        list-style: none;
        padding: 15px 10px;
        border-radius: 10px;
        background: #252525;
        margin-bottom: 15px;

        .game-thumb {
            float: left; }

        .content {
            margin-left: 70px;
            position: relative;

            h3 {
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;

                a {
                    color: #dedede;

                    &:hover {
                        color: $color_theme-2; } } }

            .review {
                line-height: 13px;
                li {
                    font-size: 13px;
                    line-height: 13px;
                    color: $color_theme-2; } }

            .follow {
                height: 28px;
                width: 28px;
                color: #dedede;
                text-align: center;
                background: $color_theme-2;
                border-radius: 50%;
                display: block;
                font-size: 14px;
                line-height: 31px;
                position: absolute;
                bottom: -3px;
                right: 10px;

                &:hover {
                    opacity: 0.9; } } } } }

/* Shop Widget */
.widget-title-shop {
    color: #dedede;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 3px solid $color_theme-2;
    margin-bottom: 10px;
    position: relative;
    padding: 14px 20px;
    background: #212121;
    line-height: 16px;

    &:after {
        position: absolute;
        content: '';
        left: 50%;
        bottom: -8px;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $color_theme-2; } }

.search-form-product {
    border: 0;
    input[type="search"] {
        border: 0;
        background: #252525;
        border-radius: 0; } }

/* Releted Product */
.widgets-releted-product {

    img {
        float: left;
        border-radius: 6px; }

    > li {
        margin-bottom: 20px;
        border-radius: 4px;
        background: #252525;
        padding: 10px;
        .content {
            margin-left: 90px;
            h3 {
                font-size: 16px;
                font-weight: 500;
                margin: 0 0 5px;
                a {
                    color: #dedede;

                    &:hover {
                        color: $color_theme-2; } } }

            .add_to_cart_button, .price {
                color: #dedede; }

            .add_to_cart_button {
                &:hover {
                    color: $color_theme-2; } }

            .review {
                line-height: 13px;
                li {
                    font-size: 13px;
                    color: $color_theme-2;
                    margin-bottom: 0;
                    line-height: 13px; } } } } }


/* Product Price */
#product-price {

    .price-filter-inner {
        background: #252525;
        padding: 25px 20px 15px;
        border-radius: 5px; } }

#slider-range {
    margin-bottom: 15px; }

.ui-widget-header {
    background: $color_theme-2; }

.ui-slider {
    position: relative;

    .ui-slider-range {
        position: absolute;
        z-index: 1;
        //font-size: .7em
        display: block;
        border: 0; } }


.ui-slider-horizontal {
    height: 7px;

    .ui-slider-handle {
        top: -8px;
 }        //border-radius: 50%

    .ui-slider-range {
        top: 0;
        height: 100%; } }

.ui-state-default, .ui-widget-content .ui-state-default {
    background: #FFF;
    outline: none;
    position: relative;
    display: inline-block;
    height: 15px;
    width: 15px;
    z-index: 2;
    transform: rotate(45deg); }

.ui-widget-content {
    background: #fff; }

.ui-widget {
    &.ui-widget-content {
        border: none; } }

.price-filter {
    span {
        font-size: 13px;
        color: #fff;

        #amount {
            width: auto;
            padding: 0;
            margin: 0;
            outline: none;
            font-size: 13px;
            border: none;
            cursor: default;
            background: transparent;
            color: #fff; } } }

.shop-catgories-links {
    margin: 0;
    padding: 20px;
    background: #252525;
    list-style: none;
    border-radius: 5px;

    li {
        a {
            display: inline-block;
            font-size: 16px;
            font-weight: 500;
            color: #fff;
            line-height: 30px;


            &:hover {
                background: $color_theme-2;
                border-radius: 4px;
                text-decoration: underline;
                padding: 0 10px; } } } }



@media (min-width: 768px) and (max-width: 991.99px) {
    .widgets-releted-product {
        > li {
            .content {
                margin-left: 75px;
                h3 {
                    font-size: 14px; }

                .review {
                    line-height: 11px;
                    li {
                        font-size: 11px;
                        line-height: 11px; } }

                .add_to_cart_button, price {
                    font-size: 12px; } } } }

    .feature-video-sm {
        padding: 5px;
        max-width: 370px;

        img {
            border-radius: 5px; }

        .popup-btn-three {
            height: 35px;
            width: 35px;
            line-height: 37px;
            font-size: 15px; } } }


@media (min-width: 992px) and (max-width:1200px) {

    .widget-social-link {
        padding: 15px 8px; }

    .widgets-recent-post {
        li {
            .content {
                h3 {
                    font-size: 15px; } } } }

    .widget_upcoming_game {
        > li {
            .content {
                margin-left: 66px;
                h3 {
                    font-size: 15px; } } } } }

@media (min-width: 768px) and (max-width:991px) {
    .widget-social-link {
        padding: 10px; }

    .widgets-recent-post {
        li {
            .content {
                margin-left: 75px;

                h3 {
                    font-size: 14px; }

                .post-date {
                    font-size: 12px; } } } }

    .widget_upcoming_game {
        > li {
            padding: 10px 6px;

            .content {
                margin-left: 65px;

                h3 {
                    font-size: 13px;
                    line-height: 16px; } } } } }

