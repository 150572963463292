.top-game-tabs {
    .torurmant-title {
        padding: 0; } }


.tim-tab-nav-game-top {
    background: url(../../media/background/stripe.png) top left;
    padding-left: 20px;
    li {
        padding: 0 15px;
        a {
            color: #fff;
            font-size: 18px;
            font-weight: 600;
            padding: 16px 0;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                width: 0;
                height: 3px;
                background: $color_theme-2;
                transition: all 0.3s ease-in-out;
                bottom: 0;
                left: 0; }

            &:hover, &.active {
                color: $color_theme-2;
                &:after {
                    width: 100%; } } } } }



.games-bg {
    background: #0d0707;

    .games-title {
        // background: #1a1c20
        background: #21212d;
        padding: 15px 20px;

        h3 {
            font-size: 18px;
            font-weight: 600;
            line-height: 20px;
            color: #fff;
            margin-bottom: 0; } }

    .top-games-item {
        background: #1a1a25;
        // background: #1a1c20
        padding: 15px 20px;
        border-radius: 5px;
        margin-bottom: 21px;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;

        &:last-child {
            margin-bottom: 0; }

        .game-user {
            float: left;
            width: 33%;

            .uthumb {
                float: left;
                border-radius: 10px;
                overflow: hidden; }

            .content {
                margin-left: 80px;
                h3 {
                    font-size: 18px;
                    line-height: 20px;
                    font-weight: 600;
                    margin-top: 2px;

                    a {
                        color: #fff;

                        &:hover {
                            color: $color_theme-3; } } }

                p {
                    margin-bottom: 0;
                    line-height: 16px; } } }
        .win {
            width: 60px;
            float: left;
            text-align: center;
            border-left: 1px solid #332b2b;
            border-right: 1px solid #332b2b;
            width: 33%;

            h3 {
                color: #fff;
                font-size: 20px;
                line-height: 20px;
                font-weight: 600; }

            h4 {
                font-size: 16px;
                font-weight: 600;
                color: #fff;
                line-height: 16px;
                margin-bottom: 0; } }

        .match {
            text-align: center;
            width: 33%;

            h3 {
                color: #fff;
                font-size: 20px;
                font-weight: 600;
                line-height: 20px; }

            h4 {
                font-size: 16px;
                font-weight: 600;
                color: #fff;
                line-height: 16px;
                margin-bottom: 0; } }

        &:hover {
            background-image: $color_gradian; } }
    .games-inner {
        padding: 20px 15px;
        // background: #0c0c0c
        background: #0f0f19; } }

.feature-game {
    position: relative;

    .blog-content {
        position: absolute;
        bottom: 0;
        padding: 20px;
        width: 100%;

        .blog-title {
            margin-bottom: 0;

            a {
                &:hover {
                    color: $color_theme-2; } } }

        p {

            font-size: 14px;
            font-weight: 700;
            color: #fff;
            line-height: 25px;
            margin-bottom: 0; } } }

.game-clan-video {
    position: relative;
    img {
        max-width: 100%; }

    .youtube-wrapper {
        padding-bottom: 35%;

        &:before {
            content: "\f04b";
            font-size: 30px;
            border: 2px solid #fff;
            border-radius: 50%;
            height: 50px;
            width: 50px;
            line-height: 48px;
            text-align: center;
            padding-left: 7px;
            margin: 0; } } }

@media (min-width: 992px) and (max-width: 1200px) {
    .games-bg {
        .top-games-item {
            padding: 15px;
            .game-user {
                width: 130px;
                .content {
                    h3 {
                        a {
                            font-size: 14px; } }

                    p {
                        font-size: 12px; } } }

            .win {
                width: 45px;

                h3 {
                    font-size: 14px; } }
            .match {
                h3 {
                    font-size: 14px; } } } } }

@media (max-width: 991px) {

    .games-bg {
        margin-bottom: 20px; }

    .feature-game {
        max-width: 370px;
        margin: 0 auto; } }

@media (max-width:768px) {
    .games-bg {
        .top-games-item {

            .game-user {
                width: 40%; }

            .win {
                width: 28%; }

            .match {
                width: 20%; } } } }

@media (max-width: 500px) {
    .tim-tab-nav-game-top {
        li {
            padding: 0 8px;
            a {
                font-size: 15px; } } }

    .games-bg {
        .top-games-item {

            .game-user {
                width: 60%;
                .content {
                    h3 {
                        font-size: 15px; } } }

            .win {
                width: 20%; }

            .match {
                width: 20%;

                h3 {
                    font-size: 16px; } } } } }

@media (max-width: 400px) {
    .games-bg {
        .games-inner {
            padding: 15px 10px; }

        .top-games-item {
            padding: 10px;

            .game-user {
                width: 140px; }

            .win {
                width: 50px; } } } }
