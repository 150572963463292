/*!
  Theme Name: GIO
  Theme URI: http://www.themeim.com/demo/gio/
  Author: Theme Im
  Author URI: http://www.themeim.com/
  Description: Multiperpose Game HTML Template
  Version: 1.0.0
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Text Domain: gameisland
  Tags: game,business,coporate,startup
  */
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base
  # Sections
    ## Header
    ## Banner
    ## Beyond
    ## Blog
    ## Video
    ## Behind
    ## Live Stream
    ## Game Strategy
    ## Add
    ## Newsletter
    ## Page Heade
    ## Feature Game
    ## Tranding Game
    ## Logo Carousel
    ## Overview
    ## Purchase Game
    ## Footer

  --------------------------------------------------------------*/
@import "generated/icons";

@import "base/mixins";
@import "base/variable";
@import "base/base";
@import "sections/element";
