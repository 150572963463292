#behind {
    background-attachment: fixed;
    background-size: cover; }


.behind-items {
    .item {
        padding: 50px;
        text-align: center;
        transition: all 0.3s ease-in-out;


        .icone-wrap {
            height: 85px;
            line-height: 85px;
            width: 100px;
            text-align: center;
            margin: 0 auto 20px;

            i {
                font-size: 80px;
                color: #a0a0a0;
                transition: all 0.3s ease-in-out; } }


        .title {
            font-size: 25px;
            font-weight: 700;
            color: #d4d4d4;
            letter-spacing: 0.10px; }

        .content {
            font-size: 16px;
            font-weight: 500;
            color: #949494; }

        &:hover {
            background: #131313;
            // transform: scale(1.02)
            z-index: 99;

            .icone-wrap {
                i {
                    color: $color_theme-2; } }

            .title {
                color: $color_theme-2; } } } }

.bg__one {
    background: #0b0b15; }

.bg__two {
    background: #1b1b27; }

@media (max-width: 1024px) {
    .behind-items {
        .item {
            padding: 30px 20px !important; } } }

@media (max-width: 768px) {

    .behind-items {
        .item {
            max-width: 90%;
            margin: 0 auto 20px; } }

    .skew__left, .skew__right {
        transform: skewX(0deg) !important;

        .items-content {
            transform: skewX(0deg) !important; } } }

@media (max-width: 500px) {
    .skew__left, .skew__right {
        transform: skewX(0deg) !important;

        .items-content {
            transform: skewX(0deg) !important; } } }
