#forum-banner {
    height: 90vh;
    background-size: cover;
    background-position: center center;
    padding-top: 160px;
    overflow: hidden;
    position: relative; }


.forum-inner {
    max-width: 1140px;
    margin: 0 auto;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    width: 100%; }

.top-forum {
    padding: 95px 0;
    h2 {
        font-size: 50px;
        font-weight: 700;
        color: #fff; }

    h3 {
        font-size: 30px;
        color: #fff;
        margin-bottom: 15px; }

    p {
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 30px; }

    .tim-btn-bg {
        padding: 14px 58px;
        font-size: 20px;
        border-radius: 0; } }

.top-user {
    text-align: right;
    img {
        max-width: 100%; } }

.top-author {
    img {
        border-radius: 50%;
        margin-bottom: 10px; }

    .game-author-name {
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 0;

        a {
            color: #fff;

            &:hover {
                color: $color_theme-2; } } }

    .author-post-count {
        font-size: 13px;
        color: #fff;
        margin: 0; } }


.top-user-details {
    background: #293241;
    padding: 15px;
    border-radius: 5px; }

.top-user-status {
    .left-side {
        width: 65%;
        float: left;
        padding-right: 15px;
        .user-pic {
            float: left;
            width: 90px;
            height: auto; }

        .content {
            margin-left: 100px;

            h3.name {
                font-size: 20px;
                color: #fff;
                margin: 0; }

            p {
                color: #fff;
                font-size: 14px;
                margin: 0; }

            p.some-text {
                margin: 0;
                font-size: 12px;
                line-height: 16px; } } }

    .right-side {
        float: right;
        width: 35%;

        .game-level {
            h4 {
                font-size: 18px;
                font-weight: 600;
                color: #fff; } } }

    .offline {
        background: #1c1c1d;
        padding: 7px;
        margin: 5px 0;

        h6 {
            font-size: 13px;
            font-weight: 500;
            color: #fff;
            margin: 0; }

        p {
            margin: 0;
            color: #fff;
            font-size: 12px;
            line-height: 14px; } } }

.game-badges {
    padding: 10px;
    background: #222223;

    h5 {
        font-size: 16px;
        color: #fff; }

    img {
        width: 35px; } }


.user-performance {
    background: #1d1d1d;
    padding: 20px;
    margin-top: 15px;
    border-radius: 5px; }

.game-collector {
    background: #141414;
    padding: 10px;
    border-radius: 5px;

    h5 {
        font-size: 18px;
        color: #fff;
        font-weight: 600; }

    .collector-items {
        background: #0e0e0e;
        padding: 5px; }
    .item {
        width: 25%;
        text-align: center;
        float: left;
        padding: 10px 0;

        a {
            h6 {
                font-size: 30px;
                font-weight: 600;
                color: #fff;
                line-height: 30px;
                margin-bottom: 0; }

            p {
                font-size: 16px;
                color: #9b9b9b;
                margin: 0;
                line-height: 16px; } } } }

.activity {
    margin-top: 10px;
    padding: 7px;
    background: #141414;
    border-radius: 5px;

    h3 {
        font-size: 16px;
        color: #fff;
        font-weight: 600;
        margin-bottom: 0; }

    p {
        color: #fff;
        margin-bottom: 0;
        line-height: 19px; } }

.achievement {
    background: #141414;
    padding: 10px;
    border-radius: 5px;

    .game_info {
        position: relative;
        height: 56px;

        .game_info_cap {
            float: left;
            margin-right: 8px;
            width: 184px;
            max-width: 33%;

            img {
                max-width: 100%; } }

        .game_name {
            a {
                color: #fff;
                font-size: 16px;
                font-weight: 600; } }

        .game_info_details {
            position: absolute;
            right: 0;
            bottom: 0;
            text-align: right;
            color: #fff;
            font-size: 15px;
            line-height: 22px; } } }

.achievement-progressbar {
    background: #282828;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px; }

.width-half {
    width: 100%;
    float: left;

    p {
        display: inline-block;
        color:#fff {}
        font-size: 16px;
        font-weight: 600;
        float: left;
        margin: 0 10px 0 0;
        left: 12px;
        line-height: 12px;

        span {
            font-size: 14px;
            margin-left: 5px; } }

    .progress {
        height: 14px;
        padding: 1px;
        border-radius: 30px;
        background: #222223;


        .progress-bar {
            border-radius: 30px;
            background: #516179; } } }

@media (max-width: 1200px) {
    .forum-inner {
        max-width: 90%; } }


@media (max-width: 1024px) {

    #forum-banner {
        padding-top: 20px; }

    .forum-inner {
        padding: 0; }

    .top-forum {
        padding: 0 0 20px;

        h2 {
            font-size: 35px;
            line-height: 35px; }

        h3 {
            font-size: 25px;
            line-height: 25px; } }

    .top-user-details {
        padding: 10px;
        margin-top: 30px; }

    .user-performance {
        padding: 10px; }

    .game-collector {
        padding: 10px;
        .collector-items {
            padding: 0; }

        .item {
            a {
                h6 {
                    font-size: 20px;
                    line-height: 22px; }

                p {
                    font-size: 14px; } } } }

    .activity {
        margin: 5px 0; }

    .achievement {
        padding: 10px; } }

@media (max-width: 500px) {
    #forum-banner {
        height: 115vh; }

    .top-user-status {
        .left-side {
            .user-pic {
                width: 70px; }

            .content {
                margin-left: 80px;

                p {
                    line-height: 20px; } } } }

    .game-badges {
        img {
            max-width: 30px;
            width: 100%; } }

    .top-user-status {
        .left-side, .right-side {
            width: 100%; } }

    .top-forum {
        h2 {
            font-size: 30px;
            line-height: 30px; }

        h3 {
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 10px; }
        p {
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 10px; }

        .tim-btn-bg {
            padding: 10px 40px; } }

    .activity {
        text-align: center;

        .float-left, .float-right {
            float: none !important; } }

    .achievement {
        .game_info {
            .game_info_details {
                position: static; } } } }

