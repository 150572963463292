#error-page {
    height: 100vh;
    text-align: center;
    position: relative;
    padding-top: 150px;

    .error-content {
        position: absolute;
        top: 56%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        background: rgba(0,0,0,0.5);
        padding: 100px 50px;
        width: 60%;
        z-index: 1;

        &:before {
            content: '';
            position: absolute;
            top: -10px;
            bottom: -10px;
            left: -10px;
            right: -10px;
            border: 5px solid rgba(0,0,0,0.5);
            z-index: -1; }


        img {
            max-width: 300px;
            margin-bottom: 40px; }

        h1 {
            font-size: 80px;
            color: #fff;
            line-height: 80px;
            margin-bottom: 30px;
 }            // font-family: $font-family-retro

        h3 {
            font-size: 50px;
            color: #fff;
            margin-bottom: 30px; } } }


@media (max-width: 991px) {
    #error-page {
        .error-content {
            top: 50%;

            h1 {
                font-size: 50px;
                margin-bottom: 20px; }

            img {
                max-width: 200px;
                margin-bottom: 20px; }

            h3 {
                font-size: 30px; } } } }

@media (max-width: 500px) {

    #error-page {
        .error-content {
            width: 70%;
            top: 50%;

            h1 {
                font-size: 40px;
                margin-bottom: 0; }

            img {
                max-width: 150px;
                margin-bottom: 20px; }

            h3 {
                font-size: 30px;
                font-size: 20px; } } } }
