#game-download {
    background: #13131f; }

.download-inner {
    .title {
        font-size: 30px;
        line-height: 40px;
        color: #dedede;
        font-weight: 700;
        margin-bottom: 20px;

        span {
            color: $color_theme-2; } }

    p {

        line-height: 30px;
        margin-bottom: 15px;
        font-size: 16px;

        &:last-child {
            margin-bottom: 0; } }

    .play-store-btn {
        margin-right: 10px; } }


.download-image-wrap {
    text-align: right;

    img {
        max-width: 100%; } }

.download-inner {
    &.d-inner-two {
        position: relative;
        margin-bottom: 80px;

        h2 {
            font-size: 40px;
            line-height: 45px;

            p {
                font-size: 16px;
                line-height: 26px;
                font-weight: 600;
                margin-bottom: 40px; } } } }


#game-download-two {
    padding: 150px 0 40px;
    background-size: cover;
    background-position: center center; }


@media (max-width: 768px) {
    .download-image-wrap {
        margin-top: 30px; }

    .download-inner {
        &.d-inner-two {
            margin-bottom: 30px;
            h2 {
                font-size: 25px;
                line-height: 30px; } }

        p {
            br {
                display: none; } } }

    #game-download-two {
        text-align: center;
        padding: 100px 0 30px; } }
