#footer {
    background: #141416; }

.footer-inner {
    position: relative;
    z-index: 1; }

.fire-bg-wrap {
    position: absolute;
    width: 100%;
    z-index: 0;
    bottom: 0; }

.footer-title {
    color: #dedede;
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 25px;
    text-transform: uppercase; }

.content {
    p {
        color: #FFF;
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 20px; }

    .fread-btn {
        color: #FFF;
        font-size: 18px;
        display: inline-block;

        &:hover {
            color: $color_theme-4; } } }

.contact-items {
    margin-bottom: 20px;
    .title {
        font-size: 16px;
        color: #d4d4d4;
        font-weight: 600;

        i {
            margin-right: 10px;
            width: 22px;
            text-align: center;
            color: $color_theme-2; } }


    .email {
        color: #e0e0e0;
        font-size: 16px;
        margin-left: 30px;
        margin-bottom: 0;
        line-height: 22px; } }

.footer-latest-post {
    transition: all 0.3s ease-in-out;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #1a1a1a;

    .feature-image {
        float: left;
        margin-right: 10px;
        position: relative;
        transition: all 0.3s ease-in-out;

        .play {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            visibility: hidden;
            opacity: 0;
            width: auto; } }

    .post-content {
        .post-title {
            margin-bottom: 0;
            font-size: 15px;
            a {

                color: #FFF;
                font-weight: 600;
                display: inherit;
                line-height: 22px; } } }

    .post-meta {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
            margin-right: 10px;
            font-size: 14px;

            &:first-child {
                a {
                    color: #FFF;
                    text-transform: uppercase;
                    font-weight: 500;

                    i {
                        margin-right: 5px; }

                    &:hover {
                        color: $color_theme-2;

                        i {
                            color: #fff; } } } }


            &:last-child {
                margin-right: 0;

                i {
                    color: $color_theme-2; } } } }

    &:hover {
        .feature-image {
            .play {
                visibility: visible;
                opacity: 1; } }


        .post-content {
            .post-title {
                text-decoration: underline; } } } }

.top-game {

    a {
        display: inline-block;
        padding: 3px;
        height: 80px;
        width: 80px;
        margin-bottom: 3px;

        img {
            width: 100%; } } }

.copy-right {
    padding: 20px 0;
    border-top: 1px solid #212121;
    border-bottom: 1px solid #212121;
    text-align: center;

    p {
        margin-bottom: 0;
        color: #d4d4d4;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 1px;

        a {
            color: $color_theme-2; } } }

.footer-menu-wrap {
    padding: 50px 0;
    background: #141416;
    text-align: center; }

.footer-logo {
    margin-bottom: 20px;
    display: block; }

.footer-social-link {
    margin: 20px 0 30px;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
        height: 40px;
        width: 40px;
        margin-right: 10px;
        text-align: center;
        line-height: 44px;
        transition: all 0.3s ease-in-out;

        i {
            color: #FFF; }

        &.fb-bg {
            background-image: url("../../media/footer/2.png"); }

        &.in-bg {
            background-image: url("../../media/footer/3.png"); }

        &.tw-bg {
            background-image: url("../../media/footer/4.png"); }

        &.gp-bg {
            background-image: url("../../media/footer/5.png"); }

        &:hover {
            opacity: 0.8; } } }

.footer-menu {
    margin: 0;
    padding: 0;
    line-height {}

    li {
        display: inline-block;
        margin: 0 20px;


        a {
            color: #fff;
            display: inline-block;
            font-size: 16px;
            font-weight: 500;

            &:hover {
                color: $color_theme-2; } } } }

/* Footer Two */
.footer-two {
    border-top: 1px solid #160000;
    padding: 80px 0 20px;
    background-size: cover;
    background-position: center center;

    .footer-latest-post {
        .post-meta {
            li {
                i {
                    color: #FFF; } } } } }

.footer-about {
    p {
        color: #cecece; } }

.footer-social-link-two {
    li {
        display: inline-block;
        margin-right: 10px;

        a {
            color: #fff;
            font-size: 25px;

            &:hover {
                color: $color_theme-2; } } } }

.tags-items {
    a {
        display: inline-block;
        color: #fff;
        padding: 4px 8px;
        background: #333;
        margin-bottom: 4px;
        margin-right: 3px;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;

        &:hover {
            background: $color_theme-2; } } }

.copyright-two {
    text-align: center;
    margin-top: 40px;
    p {
        color: #fff;
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 0;

        a {
            color: $color_theme-2; } } }

/* Footer */
#footer-three {
    padding: 100px 0 30px;
    background-size: cover;
    position: relative;
    background: #0b0d14;

    .copy-right {
        border: 0;
        padding: 0;
        margin-top: 20px;
        padding-top: 30px;
        border-top: 1px solid #1d1d1d; }

    .footer-fire {
        canvas {
            position: absolute;
            bottom: 0;
            width: 100%; } } }


.footer-about {
    .footer-social-link {
        margin-top: 50px; } }

.footer-menu-list {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        border-bottom: 1px solid #262626;
        a {
            color: #cecece;
            font-size: 16px;
            line-height: 40px;
            font-weight: 500;
            display: block;

            &:hover {
                color: $color_theme-2; } }

        &:last-child {
            border-bottom: 0; } } }

.footer-newsletter-inner {
    p {
        font-size: 16px;
        font-weight: 500;
        color: #cecece; } }

.footer-newsletter {
    input[type="text"] {
        display: block;
        padding: 10px 15px;
        background: transparent;
        border: 1px solid #262626;
        border-radius: 4px;
        margin-bottom: 20px;
        width: 100%;
        font-size: 16px;
        outline: 0;
        color: #fff;
        transition: all 0.3s ease-in-out;

        &::placeholder {
            color: #262626; }

        &:hover, &:focus {
            border-color: $color_theme-2; } }

    .tim-btn-bg {
        width: 100%;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
            background: $color_theme-4; } } }

/* Footer Clan */
#footer-clan {
    padding-top: 100px;
    position: relative;
    // background: #141416
    background: #161623; }

.footer-menu-clan {
    padding: 30px 0;

    li {
        display: inline-block;
        margin: 0 18px;
        position: relative;

        &:first-child {
            margin-left: 0; }

        &:after {
            position: absolute;
            content: '';
            right: -20px;
            height: 15px;
            width: 1px;
            background: #a5a5a5;
            bottom: 7px; }

        &:last-child {
            &:after {
                display: none; } }
        a {
            color: #a5a5a5;
            font-weight: 500;

            &:hover {
                color: $color_theme-2; } } } }

.sub-footer-inner {
    margin-top: 30px;
    border-top: 1px solid #2f2f2f;


    .copy-right {
        border: 0;
        text-align: right;
        padding: 30px 0; } }

.footer-title-clan {
    font-size: 18px;
    line-height: 25px;
    color: #fff;
    font-weight: 500; }

.footer-menu-clan-two {
    li {
        a {
            color: #a5a5a5;
            font-size: 14px;
            font-weight: 500;

            &:hover {
                color: $color_theme-2; } } } }

#footer-logo-clan {
    a {
        display: block;
        margin-bottom: 20px; }

    .copyright {
        p {
            color: #a5a5a5;
            line-height: 25px;
            font-weight: 500; } } }


.footer-address {
    p {
        color: #fff;
        font-size: 16px;
        color: #a5a5a5;
        line-height: 26px;
        margin-bottom: 0; } }

.footer-social-three {
    margin-bottom: 30px;
    li {
        display: inline-block;

        %sos-default-style {
            display: block;
            height: 25px;
            width: 25px;
            text-align: center;
            line-height: 28px;
            font-size:  14px;
            background: $color_theme-3;
            border-radius: 50%;
            color: #fff;
            margin-right: 5px;
            transition: all 0.3s ease-in-out; }

        a {
            &.fbgc {
                @extend %sos-default-style;
                background: #3b5998; }

            &.tbgc {
                @extend %sos-default-style;
                background: #1da1f2; }

            &.gbgc {
                @extend %sos-default-style;
                background: #ec1c23; }

            &.ibgc {
                @extend %sos-default-style;
                background: #e1306c; }

            &:hover {
                background: $color_theme-3; } } } }

.payment-logo {
    a {
        display: inline-block;
        margin-right: 10px; } }

.fire-mosal-left, .fire-mosal-right {
    position: absolute;
    bottom: 0;
    transform: translateX(-50%); }

.fire-mosal-left {
    left: 11%; }

.fire-mosal-right {
    right: 8%; }

@media (max-width: 1600px) {

    .fire-mosal-left {
        left: 5%; }

    .fire-mosal-right {
        right: 3%; } }



@media (max-width: 1440px) and (min-width: 1281px) {
    .fire-mosal-left {
        left: 4%; }

    .fire-mosal-right {
        right: 0%; } }

@media (max-width: 1280px) {
    .fire-mosal-left, .fire-mosal-right {
        display: none; } }

@media (max-width: 993px) {

    #footer-clan {
        .footer-menu-clan, .copy-right {
            text-align: center; }

        .footer-menu-clan {
            padding: 20px 0 10px; }

        .copy-right {
            padding-top: 0; } } }

@media (max-width: 768px) {
    .footer-menu {
        li {
            margin: 0 10px; } }

    .footer-about {
        margin-bottom: 20px; }

    .footer-menu-clan {
        li {
            margin: 0 10px;

            &:after {
                right: -12px; } } }

    .footer-title, .footer-title-clan {
        margin-top: 20px; }

    #footer-three {
        padding-top: 50px; } }
