#moderator {
    background-size: cover;
    background: #13131f; }

#moderator, #moderator-two {
    padding: 100px 0;
    position: relative; }

.moderator-member {
    text-align: center; }


.member-details {
    text-align: center;
    .name {
        color: #fff;
        font-size: 20px;
        line-height: 25px;
        color: #fff;
        margin-bottom: 0; } }

.member-thumb {
    width: 138px;
    height: 176px;
    margin: 0 auto 20px;
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;

    img {
        margin-top: 39px;
        margin-right: 8px;
        border-radius: 50%; } }

.member-social-link {
    margin-top: -5px;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
        border-radius: 3px;
        margin-right: 5px;
        height: 35px;
        width: 35px;
        line-height: 34px;
        transition: all 0.3s ease-in-out;
        border: 1px solid #fff;
        border-radius: 50%;

        a {
            color: #fff;
            font-size: 16px;
            color: #fff;
            display: block; }

        &:hover {
            border-color: $color_theme-2;

            a {
                color: $color_theme-2; } } } }

.bg-thumb {
    position: absolute;
    left: -55px;
    bottom: 0; }




@media (max-width: 991px) {
    .moderator-member {
        margin-bottom: 30px; } }

@media (max-width: 768px) {
    #moderator {
        padding: 50px 0; } }
