// Top Menu
#header {}

.header {
    transition: all 0.3s ease-in-out;

    &.header-one {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9999; }

    &.header-two, &.header-three {
        position: relative;

        .top-menu {
            background: #1f1e24;

            &:after {
                display: none; } }

        .nav {
            background-image: $color_gradian;

            .nav-wrap {
                background: transparent;

                &:before {
                    display: none; } } } }
    &.header-three {
        .top-menu {
            border-bottom: 3px solid $color_theme-2; }

        .middle-menu-wrapper {
            background: #1f1e24;

            .top-add {
                img {
                    width: 100%; } } } }

    &.fixed {
        position: fixed;
        top: -51px;
        animation: headerFixedTop .35s;
        width: 100%;
        z-index: 9999;

        .nav {
            background-image: $color_gradian; }

        .nav-wrap {
            #site-logo {
                padding: 25px 0; } } }

    &.header-three {
        .nav-wrap {
            .tim-nav {
                > li {
                    &.menu-item-has-children {
                        .sub-menu {
                            right: auto; } } } } }

        &.fixed {
            top: -153px; } }


    &.header-one {
        &.header-dark {

            &.fixed {
                position: fixed !important;
                top: -50px;

                .nav.bgb {
                    background: #000; }

                .nav-wrap {
                    #site-logo {
                        padding: 20px 0; } } } } } }

.fixed {
    .nav {
        &:after {
            display: none; } }

    .nav-wrap {
        &:before {
            display: none; }
        .tim-nav {
            > li {
                padding: 20px 0;

                &.search-btn {
                    margin: 20px 0 20px 30px !important;
                    backface-visibility: hidden; } } } } }

.top-product-items {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    justify-content: flex-end;

    .item {
        width: 48%;
        margin-left: 7px;
        padding: 10px 5px;
        background: #333;
        border-radius: 10px;

        &:first-child {
            margin-left: 0; }


        img {
            float: left;
            max-width: 100%; }

        .content {
            margin-left: 65px;
            padding: 13px 0px;

            h3 {
                font-size: 14px;
                margin-bottom: 0;
                font-weight: 500;

                a {
                    color: #888888;

                    &:hover {
                        color: $color_theme-2; } } } } } }

.top-menu-search-form {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid #888;
    width: 300px;
    display: flex;
    padding: 5px 10px;
    border-radius: 4px;

    input {
        width: 100%;
        border: 0;
        outline: 0;
        background: transparent;
        color: #888888;

        &::placeholder {
            color: #888; } }

    button {
        background: transparent;
        border: 0;
        outline: 0;
        color: #888; } }


.header {
    &.transperant-wbg {
        &.fixed {
            top: 0;
            .nav-wrap {
                .tim-nav {
                    > li {
                        padding: 25px 0; } } } } } }

@-webkit-keyframes headerFixedTop {
    0% {
        opacity: 0;
        transform: translateY(-100%); }

    100% {
        opacity: 1;
        transform: translateY(0); } }



@keyframes headerFixedTop {
    0% {
        opacity: 0;
        transform: translateY(-100%); }

    100% {
        opacity: 1;
        transform: translateY(0); } }

// .blog-post-single
//     background: #000

//     #header
//         position: relative

//         .nav-wrap
//             background: #1f1e24

//             &:before
//                 border-top: 109px solid #1f1e24

//             &:after
//                 background: #1f1e24

.top-menu-wrap {
    background: #1f1e24;
    padding: 11px 0;
    position: relative;


    &:before, &:after {
        position: absolute;
        content: '';
        top: 0; }

    &:before {
        left: -400px;
        min-width: 400px;
        height: 50px;
        background: #1f1e24; }

    &:after {
        width: 0;
        height: 0;
        border-top: 0px solid transparent;
        border-bottom: 50px solid transparent;
        border-left: 50px solid #1f1e24;
        right: -50px; } }

.top-menu {
    // background: #1f1e24

    .head-social-link {
        margin: 0;
        padding: 0;
        list-style: none;
        float: left;
        display: inline-block;
        margin-right: 20px;

        li {
            display: inline-block;
            margin-right: 10px;

            a {
                display: block;
                font-size: 16px;
                color: #FFF;

                &:hover {
                    color: $color_theme-2; } } } } }

.header-text {

    .tour-title {
        font-size: 14px;
        line-height: 24px;
        color: #fff;
        background: #000;
        padding: 0 5px;
        display: inline-block;
        margin-right: 5px; }

    .ah-headline {
        color: #FFF;
        margin-bottom: 0;
        font-size: 14px;
        width: 300px;
        overflow: hidden;
        display: inline;

        &.slide {
            .ah-words-wrapper {
                vertical-align: middle; } } } }

.user-link {
    margin: 0;
    padding: 0;
    list-style: none;

    &.nav {
        background: transparent !important; }

    li {
        display: inline-block;
        margin-right: 30px;

        a {
            display: block;
            color: #FFF;
            i {
                margin-right: 13px; }

            &:hover {
                color: $color_theme-2;

                i {
                    color: #fff; } } }

        &:last-child {
            margin-right: 0; } } }

.header-one {
    .nav {
        position: relative;

        &:after {
            position: absolute;
            content: '';
            background: $color_gradian;
            width: calc(50% - 555px);
            height: 110px;
            right: 0; } } }

// .blog-post-single
//     .header-one
//         .nav
//             &:after
//                 background-image: $color_gradian

.nav-wrap {
    background-image: $color_gradian;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        top: 0;
        left: -50px;
        width: 0;
        height: 0;
        border-left: 50px solid transparent;
        border-right: 0px solid transparent;
        border-top: 110px solid #272737; }



    #site-logo {
        display: inline-block;
        float: left;
        padding: 40px 0; }


    .tim-nav {
        margin: 0;
        padding: 0;


        > li {
            padding: 40px 0;
            display: inline-block;

            &.menu-item-has-children {

                > a {
                    &:after {
                        position: absolute;
                        content: '\f107';
                        font: normal normal normal 14px/1 FontAwesome;
                        right: 0;
                        top: 50%;
                        transform: translateY(-58%);
                        color: #FFF; } } }

            a {
                padding: 0 20px;
                color: #FFF;
                font-size: 20px;
                font-weight: 500;
                display: inline-block;
                position: relative;

                &:hover {
                    color: $color_theme-2; } }

            &.search-btn {
                height: 45px;
                width: 60px;
                background: $color_theme;
                text-align: center;
                line-height: 44px;
                border-radius: 5px;
                margin: 32px 0 32px 30px;
                background: #1f1e24;
                padding: 0;
                cursor: pointer;
                font-size: 18px; }

            &.menu-item-has-children {
                .sub-menu {
                    text-align: left;
                    position: absolute;
                    padding: 10px 20px;
                    width: 240px;
                    background: #1f1e24;
                    top: 120%;
                    left: auto;
                    margin: 0;
                    border-top: 1px solid $color_theme-2;
                    transition: all 0.3s ease-in-out;
                    opacity: 0;
                    visibility: hidden;
                    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.05);
                    height: auto;
                    z-index: 9999;

                    li {
                        display: block;



                        &:last-child {
                            border-bottom: 0; }

                        a {
                            color: #fff;
                            padding: 3px 0;
                            font-size: 16px;

                            &:hover, &.current_page {
                                color: $color_theme-2; } } }



                    &.mega-menu {
                        width: 100%;
                        padding: 20px 30px;

                        > li {
                            width: 25%;
                            float: left;

                            h3 {
                                font-size: 20px;
                                font-weight: 600;
                                color: #fff;
                                margin-left: 15px; } }



                        li {

                            .sub-menu {
                                background-color: transparent;
                                border-top-color: transparent;
                                float: left;
                                padding: 0 15px;



                                li {
                                    display: block;
                                    a {
                                        display: block;
                                        padding: 3px 0; } } } } } }




                .mega-menu-inner {
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    left: 0;
                    top: 110%;
                    width: 100%;
                    transition: all 0.3s ease-in-out;

                    .sub-menu {
                        top: auto;
                        backface-visibility: hidden;

                        // &.mega-menu
                        //     li
                        //         &:not(:last-child)
                        //             .sub-menu
 }                        //                 border-right: 1px solid rgba(255,255,255,0.1) !important

                    .torurmant {
                        background: #131313; }

                    .trurnament-inner {
                        .tournaments-details {
                            .details {
                                a.tim-btn {
                                    color: #fff; } } } } } }




            &:nth-last-child(-n+3) {
                ul {
                    left: auto;
                    right: 0;
                    li.menu-item-has-children {
                        position: relative;
                        > ul.sub-menu {
                            right: 0;
                            right: 110%; } } } }


            ul {
                > li.menu-item-has-children {
                    position: relative;

                    ul.sub-menu {
                        top: 20px;
                        left: auto;
                        right: -120%;
                        opacity: 0;
                        visibility: hidden;
                        transition: all 0.3s ease-in-out; }

                    &:hover {
                        ul.sub-menu {
                            opacity: 1;
                            visibility: visible;
                            top: -11px; } } } }



            &:hover {
                .dot, .dot:before, .dot:after {
                    background: $color_theme-2; }
                .dot {
                    animation: color 1000ms infinite ease-in-out;
                    animation-delay: 800ms; }

                .dot:before {
                    animation: color 1000ms infinite ease-in-out;
                    animation-delay: 600ms; }

                .dot:after {
                    animation: color 1000ms infinite ease-in-out;
                    animation-delay: 400ms; }

                .sub-menu {
                    opacity: 1;
                    top: 100%;
                    visibility: visible;
                    z-index: 20; }

                .mega-menu-inner {
                    opacity: 1;
                    visibility: visible;
                    top: 100%;
                    z-index: 9999;

                    .sub-menu {
                        opacity: 1;
                        visibility: visible;
                        position: relative;
                        top: 0; } } } } } }

.ah-headline {
    span {
        color: #fff;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 1px; } }

.header.header-dark {
    // .top-menu
    //     background: darken( $color_theme, 10% )

    // .nav
    //     &.bgb
    //         background: #131313
    .nav-wrap {
        &:after {
            height: 110px; } } }

.mask-overlay {
    content: '';
    position: fixed;
    background: rgba(0 ,0 ,0, .8);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999; }

.active {
    .mobile-menu-inner {
        left: 0; } }

.close-menu {
    background: #000;
    color: #000;
    position: absolute;
    right: 10px;
    top: 10px;
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 40px;
    padding: 5px;

    .bar {
        display: block;
        position: relative;
        margin-bottom: 3px;
        width: 100%;
        height: 2px;
        background-color: #fff;
        transition: all 0.25s ease-in-out;

        &:first-child {
            margin-bottom: 5px;
            transform: rotate(-45deg) translate(-6px, 6px); }

        &:last-child {
            transform: rotate(45deg) translate(1px, 1px); } } }

//Back To Top
.switch-top {
    position: fixed;
    bottom: -42px;
    right: 25px;
    transition: all 0.3s ease-in-out;
    z-index: 9999;

    p {
        display: inline-block;
        float: left;
        margin: 0 20px 0 0;
        color: #d52b31;
        line-height: 40px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
        font-size: 14px;
        font-weight: 500; }

    &:hover {
        p {
            opacity: 1;
            visibility: visible; } } }

.return-to-top {
    position: fixed;
    bottom: -42px;
    right: 42px;
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    cursor: pointer;
    z-index: 998;
    border-radius: 50%;
    opacity: 0;
    transition: bottom .5s ease,opacity .5s ease;

    > i {
        position: relative;
        overflow: hidden;
        font-size: 12px;
        width: inherit;
        height: inherit;
        line-height: inherit;
        display: block;
        color: transparent;
        text-shadow: 0 0 #fff, 0 50px #fff;
        transition: text-shadow .2s ease;
        z-index: 1; }

    &:after, &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        transform: scale(1);
        border-radius: inherit; }

    &:before {
        background: transparent;
        opacity: 1;
        transition: all .3s ease;
        transition: transform .5s ease,opacity .6s ease; }

    &:after {
        background: #272727;
        box-shadow: 0 0 0 0 transparent;
        transition: all .3s ease; }

    &:hover {
        background: $color_theme-2;

        &:after {
            transform: scale(1.07);
            background: $color_theme-2;
            box-shadow: 0 10px 20px 8px rgba(0,0,0,.15); }

        > i {

            text-shadow: 0 -42px #fff, 0 0 #fff; } } }


.return-to-top.back-top {
    bottom: 42px;
    opacity: 1; }

.switch-top.back-top {
    bottom: 20px;
    opacity: 1;

    .top-menu {
        .head-social-link {
            li {
                margin-right: 7px; } } }

    .nav-wrap {
        .tim-nav {
            > li {
                a {
                    padding: 0 15px; }

                &.menu-item-has-children {
                    &:after {
                        right: -5px; } } } } } }

.top-search-form {
    position: absolute;
    right: 0;
    height: 80px;
    background: #1f1e24;
    padding: 14px 20px;
    width: 40%;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    bottom: -100px;
    z-index: 9999;

    .search-form {
        border: 1px solid rgba(255,255,255,0.5);
        padding: 5px;
        border-radius: 5px;
        input {
            width: 90%;
            background: transparent;
            border: 0;
            height: 40px;
            padding: 10px;
            outline: 0;
            color: #fff;
            z-index: 1;
            position: relative;
            font-weight: 600; }

        button {
            border: 0;
            background: transparent;
            color: #fff; } } }

.top-search-form {
    &.active {
        opacity: 1;
        visibility: visible;
        bottom: -80px;
        z-index: 999; } }

.sea-fire {
    canvas {
        position: absolute;
        top: 10px;
        left: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        z-index: 0; } }

#mobile-nav-wrap, .mobile-menu-inner {
    display: none; }


/* Transperant Header */
#header {
    &.transperant-wbg {
        background: rgba(0,0,0,0.5);
        border-bottom: 1px solid #474f4f;
        .nav-wrap {
            background: transparent;
            &:after, &:before {
                display: none; } } } }

/* Clan Header */
#header {
    &.clan-header {

        .top-menu {
            background: #1b1a24;

            .top-menu-wrap {
                background-color: transparent;

                &:before, &:after {
                    display: none; } } }

        .nav-wrap {
            background: transparent;

            &:before, &:after {
                display: none; }

            #site-logo {
                padding: 36px 0; }

            .tim-nav {
                > li {
                    padding: 36px 0; } } } }

    &.fixed {
        position: fixed; } }

.tim-nav {
    .top-author {
        text-align: center;

        img {
            width: 100%; } } }

/* Page Loader */
#loader-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background: radial-gradient(#eeeeee, #444444);
    top: 0;
    left: 0;
    z-index: 99999; }

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 175px;
    height: 100px;

    span {
        display: block;
        background: #ccc;
        width: 7px;
        height: 10%;
        border-radius: 14px;
        margin-right: 5px;
        float: left;
        margin-top: 25%;

        &:last-child {
            margin-right: 0px; }

        &:nth-child(1) {
            animation: load 2.5s 1.4s infinite linear; }

        &:nth-child(2) {
            animation: load 2.5s 1.2s infinite linear; }

        &:nth-child(3) {
            animation: load 2.5s 1s infinite linear; }

        &:nth-child(4) {
            animation: load 2.5s 0.8s infinite linear; }

        &:nth-child(5) {
            animation: load 2.5s 0.6s infinite linear; }

        &:nth-child(6) {
            animation: load 2.5s 0.4s infinite linear; }

        &:nth-child(7) {
            animation: load 2.5s 0.2s infinite linear; }

        &:nth-child(8) {
            animation: load 2.5s 0s infinite linear; }

        &:nth-child(9) {
            animation: load 2.5s 0.2s infinite linear; }

        &:nth-child(10) {
            animation: load 2.5s 0.4s infinite linear; }

        &:nth-child(11) {
            animation: load 2.5s 0.6s infinite linear; }

        &:nth-child(12) {
            animation: load 2.5s 0.8s infinite linear; }

        &:nth-child(13) {
            animation: load 2.5s 1s infinite linear; }

        &:nth-child(14) {
            animation: load 2.5s 1.2s infinite linear; }

        &:nth-child(15) {
            animation: load 2.5s 1.4s infinite linear; } } }

@keyframes load {
    0% {
        background: #ccc;
        margin-top: 25%;
        height: 10%; }

    50% {
        background: #444;
        height: 100%;
        margin-top: 0%; }

    100% {
        background: #ccc;
        height: 10%;
        margin-top: 25%; } }

@keyframes color {
    0% {
        background: #fff;
 }        // transform: scale(0)

    50% {
        background: #fff;
 }        // transform: scale(.5)

    100% {
        background: $color_theme-4;
 } }        // transform: scale(1)

$color: blue;

.tab {
    padding: 20px;
    position: relative;
    overflow: hidden;
    background: #1f1e24;
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid rgba(255,255,255,0.1); }

.tabs {
    //display: table
    position: relative;
    overflow: hidden;
    margin: 0;
    text-align: left;
    float: left;
    width: 20%;


    li {
        // float: left
        line-height: 38px;
        overflow: hidden;
        padding: 0;
        position: relative; }


    a {

        color: #fff;
        font-weight: 500;
        width: 100%;
        display: block;
        letter-spacing: 0;
        outline: none;
        padding: 0 20px;
        text-decoration: none;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        border-bottom: 1px solid #303030;
        font-size: 16px !important; } }

.nav-wrap {
    .tabs_item {
        display: none;
        padding: 30px 0;
        margin-left: 20%;
        padding: 20px;

        .top-team {
            text-align: center;
            > a {
                background: #131313;
                padding: 10px;
                border-radius: 4px; }
            h3 {
                a {
                    padding: 0;

                    &:hover {
                        color: $color_theme-2; } } } }


        &:first-child {
            display: block; }

        .nav-top-author-inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around; }

        .top-author {
            h3 {
                line-height: 16px;
                a {
                    font-size: 16px; } } }

        .torurmant {
            max-width: 730px;
            margin: 0 auto;

            img {
                height: 120px;
                width: 120px; }
            .link-btn {
                position: absolute;
                color: $color_theme-2;
                font-size: 16px;
                font-weight: 600;

                &:hover {
                    color: $color_theme; } } }

        .torurmant-game {
            .tim-btn-bg {
                padding: 17px 55px;
                background: transparent;
                border: 1px solid #fff;

                &:hover {
                    color: #fff;
                    border-color: $color_theme-2; } } }

        .tags {
            a {
                font-size: 14px;
                color: #fff; } }

        .top-user-details {
            text-align: left;
            padding: 10px;

            .game-badges {
                img {
                    width: 24px; } } }

        .top-user-status {
            .left-side {
                width: 70%;

                .user-pic {
                    width: 85px; }

                .content {
                    margin-left: 100px;

                    p {
                        line-height: 18px;
                        font-size: 14px; }

                    h3.name {
                        font-size: 15px;
                        margin-bottom: 10px; } } }

            .right-side {
                width: 30%;

                .game-level {
                    h4 {
                        font-size: 16px; } } }

            .game-badges {
                padding: 7px;

                h5 {
                    font-size: 14px;
                    margin-bottom: 3px; } } } } }

.current a {
    color: #fff;
    background: #303030;
    width: 100%; }

.loader .loader-inner {
    width: 12px;
    height: 12px;
    border: 2px solid #f4c842;
    margin: 0 auto;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    animation-iteration-count: infinite;
    animation-duration: 1000ms; }

.loader .loader-inner:nth-child(1) {
    animation-name: loading-1;
    animation-delay: 500ms; }

.loader .loader-inner:nth-child(2) {
    animation-name: loading-2;
    animation-delay: 0ms; }

.loader .loader-inner:nth-child(3) {
    animation-name: loading-3;
    animation-delay: 500ms; }

.loader .loader-inner:nth-child(4) {
    animation-name: loading-4;
    animation-delay: 0ms; }

@keyframes loading-1 {
    50% {
        transform: translate(150%,150%) scale(2,2); } }
@keyframes loading-2 {

    50% {
        transform: translate(-150%,150%) scale(2,2); } }

@keyframes loading-3 {
    50% {
        transform: translate(-150%,-150%) scale(2,2); } }

@keyframes loading-4 {
    50% {
        transform: translate(150%,-150%) scale(2,2); } }


@media (max-width: 1199px) {
    .header-one {
        .nav {
            &:after {
                width: calc(50% - 465px);
                z-index: -1; } } } }

@media (max-width: 1200px) {
    .top-menu {
        .head-social-link {
            li {
                margin-right: 5px; } } }

    .nav-wrap {
        .tim-nav {
            > li {
                a {
                    padding: 0 15px; }

                &.menu-item-has-children {
                    &:after {
                        right: -4px; } } } } }

    .nav-wrap {
        .tabs_item {
            .top-author {
                h3 {
                    a {
                        font-size: 14px; } } } } }

    .top-product-items {
        .item {
            padding: 5px;

            img {
                width: 45px;
                margin-top: 7px; }

            .content {
                margin-left: 50px; } } }

    .header {
        &.header-three {
            &.fixed {
                top: -50px; } } } }

@media (max-width: 1024px) {

    .header-one {
        .nav:after {
            height: 70px; } }

    .nav-wrap {

        #site-logo {
            padding: 20px 0; }

        .tim-nav {

            > li {

                padding: 20px 0;

                &.search-btn {
                    margin: 12px 0 12px 10px;
                    width: 50px; }

                .dot {
                    bottom: 17px; }

                a {
                    font-size: 16px;

                    .dot {
                        bottom: -5px; }

                    &:after {
                        right: -5px; } }

                &.menu-item-has-children {
                    .sub-menu {
                        width: 160px; } } } } }

    .middle-menu-wrapper {
        display: none; }

    .nav-wrap:before {
        border-top: 69px solid #272737; } }

@media (max-width: 992px) {
    .header {
        display: none; }

    #nav-toggle {
        margin-right: 0;
        float: right;
        width: 30px;
        padding: 7px 0;

        .toggle-inner {
            position: relative;

            > span {
                display: block;
                position: relative;
                margin-bottom: 5px;
                width: 100%;
                height: 2px;
                background-color: #fff;
                transition: all 0.25s ease-in-out;

                &:nth-child(2), &:nth-child(3) {
                    width: 24px;
                    margin-left: 6px;
                    background-color: $color_theme-2 !important; }

                &:nth-child(4) {
                    margin-bottom: 0; } } }

        &.active {
            .toggle-inner {
                > span {
                    &:nth-child(2),&:nth-child(3) {
                        opacity: 0; }
                    &:nth-child(1) {
                        transform: rotate(45deg) translate(7px, 7px); }
                    &:nth-child(4) {
                        transform: rotate(-45deg) translate(8px, -8px); } } } } }

    #mobile-nav-wrap {
        display: block;
        background: $color_gradian;
        padding: 16px 20px;

        #mobile-logo {
            float: left;
            padding: 5px 0;

            img {
                width: 60px; } }

        .user-link {
            padding: 5px 0;

            li {
                margin-right: 10px; } }

        &.mnav-fixed {
            position: fixed;
            top: 0;
            width: 100%;
            left: 0;
            z-index: 9999;
            animation: headerFixedTop .35s; } }

    .mobile-menu-inner {
        display: block;
        width: 350px;
        text-align: center;
        background: #1f1e24;
        padding: 40px 20px 20px;
        position: absolute;
        z-index: 9999;
        left: -100%;
        transition: all 0.7s cubic-bezier(0.65, -0.22, 0.35, 1.13);
        height: 100vh;
        overflow-x: hidden;
        overflow-y: scroll;
        top: 0;

        &.mnav-fixed {
            position: fixed; }

        #mobile-logo {
            margin-bottom: 30px; }

        .mob-nav {
            margin: 0;
            padding: 0;
            text-align: left;
            list-style: none; } }

    .accordion-menu {
        width: 100%;
        max-width: 350px;
        margin: 20px auto 50px;
        text-align: left;
        border-radius: 4px;

        > li {
            position: relative;
            padding: 5px 0;
            border-bottom: 1px solid #333;

            &.single-link {
                margin: 3px 0; }

            .dropdownlink {
                &:before {
                    content: "\f105";
                    position: absolute;
                    right: 10px;
                    top: 9px;
                    font: normal normal normal 14px/1 FontAwesome;
                    transition: all 0.3s ease-in-out;
                    font-size: 16px; } }

            &.open {
                .dropdownlink {
                    &:before {
                        content: "\f107";
                        position: absolute;
                        right: 10px;
                        font: normal normal normal 14px/1 FontAwesome;
                        transition: all 0.3s ease-in-out;
                        font-size: 16px; } }

                > a {
                    border-bottom: 1px solid #333; } }
            > a {

                font-weight: 500;
                font-size: 18px;
                text-decoration: none;
                text-transform: uppercase;
                padding: 10px 0;
                color: #fff; } }

        .accordion-menu li.open .dropdownlink {
            color: $color_theme;
            .fa-angle-down {
                transform: rotate(180deg); } }

        .accordion-menu li:last-child .dropdownlink {
            border-bottom: 0; }

        .dropdownlink {
            cursor: pointer;
            display: block;
            padding: 3px 0;
            font-size: 18px;
            color: #fff;
            position: relative;
            transition: all 0.4s ease-out;

            i {
                position: absolute;
                top: 13px;
                left: 16px;
                font-size: 14px; }

            .fa-angle-down {
                right: 12px;
                left: auto; }

            &:last-child {
                border-bottom: none; } }

        .submenuItems {
            display: none;
            background: transparent;
            padding: 5px 0;

            li {

                &:last-child {
                    border-bottom: none; } } }

        .submenuItems a {
            display: block;
            color: #FFF;
            transition: all 0.4s ease-out;
            text-decoration: none;
            font-size: 16px;

            &:hover, &:focus {
                color: $color_theme-4; } } }

    #moble-search {
        border: 1px solid #fff;
        display: flex;
        border-radius: 4px;

        input[type="text"] {
            background: transparent;
            height: 45px;
            color: #fff;
            border: 0;
            padding: 0 10px;
            width: 88%;
            outline: 0;

            &::placeholder {
                color: rgba(255,255,255,0.7); } }

        button[type="submit"] {
            background: transparent;
            border: 0;
            color: #fff; } }

    @-webkit-keyframes headerFixedTop {
        0% {
            opacity: 0;
            transform: translateY(-100%); }

        100% {
            opacity: 1;
            transform: translateY(0); } }

    @keyframes headerFixedTop {
        0% {
            opacity: 0;
            transform: translateY(-100%); }

        100% {
            opacity: 1;
            transform: translateY(0); } } }

@media (max-width: 768px) {
    #mobile-nav-wrap {
        padding: 8px 20px !important; } }

@media (max-width: 500px) {
    .mobile-menu-inner {
        width: 90%; }

    .switch-top {
        right: 10px; } }
