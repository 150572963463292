#faq {
    padding: 100px 0;
    background-size: cover;
    background-position: center center;
    // background: #1a1c20
    background: #0d0f17;


    .section-title {
        margin-bottom: 0;
        h2 {
            color: #dedede;
            margin-bottom: 50px;
            text-align: left;
            font-size: 40px; } }

    .tim-btn {
        margin-top: 20px;
        font-size: 20px;
        padding: 15px 40px; } }







.card-body {
    padding: 15px 0; }

.card-header {}


.card {
    background: transparent;
    border: 0;
    border-radius: 0;

    .card-header {
        padding: 0;
        border-top: 1px solid #6b7077!important;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        background: transparent;
        border-bottom: 0;

        h5 {
            position: relative;

            button {
                color: #fff;
                font-size: 20px;
                border-radius: 4px;
                transition: all 150ms ease-in-out;
                display: block;
                width: 100%;
                border: 0;
                border-radius: 0;
                text-align: left;
                padding: 10px 20px 10px 40px;
                text-decoration: none; } }

        &:hover {
            border-top-color: $color_theme-2 !important;
            background: $color_theme-2; } }

    &.card-active {
        .card-header {
            border-top-color: $color_theme-2 !important; } } }



#accordion .card.card-active button,
#accordion .card.card-header button:hover,
#accordion .card.card-header button:focus {
    border-top: 1px solid $color_theme-2;
    color: #fff;
    position: relative;
    text-decoration: none;
    background: $color_theme-2;
    backface-visibility: hidden; }


.card-header > h5:before {
    content: "\f068";
    display: block;
    position: absolute;
    font-size: 25px;
    top: 50%;
    left: 20px;
    z-index: 9;
    font-family: FontAwesome;
    transition: all 0.5s;
    transform: translateY(-50%);
    font-size: 12px;
    color: #FFF; }

.collapsed:before {
    content: "\f067";
    display: block;
    position: absolute;
    top: 50%;
    left: 20px;
    z-index: 9;
    transition: all 0.5s;
    font-family: FontAwesome;
    transform: translateY(-50%);
    font-size: 12px; }

@media (max-width: 768px) {
    #faq {
        padding: 50px 0; }

    .faq {
        .panel-group {
            margin-bottom: 20px; } }

    #faq {
        .section-title {
            h2 {
                font-size: 35px;
                margin-bottom: 30px; } }

        .tim-btn {
            &.tim-btn-bg {
                margin-bottom: 20px; } } } }

@media (max-width: 500px) {
    #faq {
        .section-title {
            h2 {
                font-size: 25px;
                margin-bottom: 10px; } } }

    .panel-group {
        .panel-title {
            a {
                font-size: 16px; } } } }

