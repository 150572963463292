#banner {
    background: #040404;
    height: 100vh;

    .banner-slider {
        position: relative;

        .swiper-slide {
            height: 100vh;
            background-size: cover;
            background-position: center center; }

        .slider-content, .slider-content-two {
            position: absolute;
            top: 57%;
            transform: translateY(-50%);
            background: rgba(0, 0, 0, 0.83);

            h2 {
                font-size: 60px;
                line-height: 70px;
                color: #FFF;
                font-weight: 700;
                margin: 0;

                span {
                    color: $color_theme-2; } } }

        .slider-content {
            border-radius: 5px;
            padding: 50px 30px;
            transition: all ease-in-out;
            left: 21%;

            &:before {
                position: absolute;
                top: -10px;
                bottom: -10px;
                right: -10px;
                left: -10px;
                border: 2px solid rgba(0, 0, 0, 0.83);
                content: '';
                border-radius: 4px;
                z-index: -1; } }

        .slider-content-two {
            padding: 50px 50px 50px 0;
            left: 23%;

            &:before {
                position: absolute;
                content: '';
                height: 100%;
                width: 50px;
                left: -50px;
                background: rgba(0,0,0,0.83);
                top: 0;
                backface-visibility: hidden; }

            &:after {
                content: '';
                position: absolute;
                right: -236px;
                top: 0;
                width: 0;
                height: 0;
                border-top: 310px solid transparent;
                border-bottom: 0 solid transparent;
                border-left: 236px solid rgba(0, 0, 0, 0.83);
                backface-visibility: hidden; } }

        .slider-content-3 {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;

            img {
                //transform: translateY(28%)
                min-width: 400px;
                width: 100%; }

            .content {
                transform: translateY(-92%);
                text-align: center;
                position: absolute;
                top: 50%;
                width: 100%;



                h2 {
                    font-size: 50px;
                    color: #fff;
                    font-weight: 700;
                    margin: 0;

                    span {
                        color: $color_theme; } } } }



        .fire-nav-prev, .fire-nav-next {
            top: 50%;
            transform: translateY(-50%); }

        .fire-nav-prev {
            left: 50px; }

        .fire-nav-next {
            right: 50px; }

        &:hover {
            .fire-nav-prev, .fire-nav-next {
                opacity: 1;
                visibility: visible;
                z-index: 999; } } } }

.popup-btn {
    padding: 10px 21px;
    border: 2px solid #FFF;
    font-size: 20px;
    color: #FFF;
    text-transform: uppercase;
    line-height: 25px;
    display: inline-block;
    margin-top: 20px;

    i {
        font-size: 9px;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        color: #0d1614;
        padding-left: 2px;
        background: #fff;
        line-height: 20px;
        text-align: center;
        margin-right: 8px;
        vertical-align: middle; }

    &:hover {
        background: $color_theme-2;
        border-color: $color_theme-2;
        color: #fff;

        i {
            background: #fff;
            color: $color_theme-2; } } }

/* Magazine */
#banner-magazine {
    background-size: cover; }

.game-items {
    .item {
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
            transition: all 0.3s ease-in-out; }

        .game-content {
            position: absolute;
            left: 0;
            bottom: 20px;
            padding: 0 20px;

            .game-title {
                font-size: 20px;
                margin-top: 0;
                font-weight: 600;
                margin-bottom: 5px;
                line-height: 25px;

                a {
                    color: #fff;

                    &:hover {
                        color: $color_theme-2; } } }

            .meta {
                li {
                    margin-right: 15px;
                    a {
                        color: #FFF;

                        i {
                            margin-right: 5px; }

                        &:hover {
                            color: $color_theme-2; } } } } }
        &:hover {
            cursor: e-resize;
            img {
                transform: scale(1.1); } } } }

.review {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
        color: #ffeb3b;

        &:hover {
            color: $color_theme-2;

            i {
                color: #FFF; } } } }

.game-slider {
    margin-bottom: 30px;
    .fire-nav-prev, .fire-nav-next {
        z-index: 99;
        top: 50%;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease-in-out;

        &:before {
            border-color: #fff; }

        i {
            color: #fff; } }

    .fire-nav-prev {
        left: 30px; }

    .fire-nav-next {
        right: 30px; }

    &:hover {
        .fire-nav-prev, .fire-nav-next {
            opacity: 1;
            visibility: visible;
            z-index: 999; } } }

/* Banner Magazine Two */
.banner-slider-zommbie {
    height: 85vh;
    position: relative;

    .fire-nav-prev, .fire-nav-next {
        top: 50%;
        z-index: 111; }

    .fire-nav-prev {
        left: 40px; }

    .fire-nav-next {
        right: 40px; } }

.mgslider-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    h2 {
        color: #fff;
        font-size: 60px;
        line-height: 65px;
        margin: 0;
        text-shadow: 0px 9px 6.48px rgba(21, 21, 21, 0.24);
        backface-visibility: hidden;
        text-transform: uppercase;
        font-weight: 700; }

    .delemitter {
        height: 50px;
        background-size: contain;
        max-width: 230px;
        display: block;
        margin: 30px auto; }

    p {
        font-size: 20px;
        line-height: 30px;
        color: #fff;
        margin-bottom: 30px; }

    .tim-btn {
        padding: 14px 51px;
        margin-right: 5px;
        font-size: 20px;
        font-weight: 600;
        line-height: 18px;
        backface-visibility: hidden;
        margin-right: 20px;
        border-radius: 4px;

        &:after {
            background: $color_theme-3; }

        &:hover {
            color: #fff;
            border-color: $color_theme-3; }



        &.tim-btn-bg {
            padding: 15px 62px;
            font-size: 18px;
            backface-visibility: hidden;


            &:after {
                background: #fff; }

            &:hover {
                background: transparent;
                color: #000;
                border-color: #fff; } } }

    &.text-left {
        left: 25%;
        top: 50%;
        transform: translateY(-50%);

        img {
            position: absolute;
            left: -115px;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1; } } }






/* Clan Slider */
.banner-slider-clan {
    height: 85vh;

    .swiper-slide {
        background-size: cover;
        background-position: center center; }

    .clan-slider-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;

        h2 {
            font-size: 60px;
            font-weight: 700;
            line-height: 70px;
            color: #fff;
            text-transform: uppercase;
            margin: 0; }

        p {
            font-size: 18px;
            font-weight: 700;
            color: #fff; }

        .skew-bg {
            padding: 35px 140px 35px 40px;

            &:before, &:after {
                position: absolute;
                content: '';
                z-index: -1; }

            &:before {
                background-color: rgba($color_theme-2, 0.5);
                transform: skewX(-5deg);
                z-index: 0;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                z-index: -1; }


            &:after {
                transform: skewX(-5deg);
                left: -5px;
                top: -5px;
                bottom: -5px;
                right: -5px;
                border: 5px solid rgba(255,255,255,0.3); } }

        &.text-center {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: -1; } } } }

.round-bg {}


@media (max-width: 1280px) {
    .mgslider-content {
        h2 {
            font-size: 60px;
            line-height: 60px; }

        .delemitter {
            width: 185px;
            height: 38px; } } }

@media (max-width: 1024px) {
    #banner {
        .swiper-wrapper {
            .slider-content {
                h2 {
                    font-size: 40px;
                    line-height: 40px; }

                .popup-btn {
                    padding: 10px 15px;
                    font-size: 16px;
                    line-height: 20px; } } } }

    #banner {
        .banner-slider {
            .slider-content-two {

                &:after {
                    border-top: 299px solid transparent; } } } }

    #banner {
        .banner-slider {
            .slider-content-3 {
                .content {
                    transform: translateY(-85%);
                    h2 {
                        font-size: 40px; } } } } }


    #banner {
        .banner-slider {
            .fire-nav-prev {
                left: 20px; }

            .fire-nav-next {
                right: 20px; } } } }

@media (max-width: 991px) {
    #banner {
        .banner-slider {
            .slider-content, .slider-content-3, .slider-content-two {
                top: 50%; }


            .slider-content-3 {
                .content {
                    h2 {
                        font-size: 30px; } } }

            .slider-content, .slider-content-two {
                h2 {
                    font-size: 40px;
                    line-height: 50px; } }

            .slider-content-two {
                &:after {
                    border-top: 255px solid transparent;
                    border-left: 215px solid rgba(0, 0, 0, 0.83);
                    right: -215px; } } } }

    .popup-btn {
        padding: 8px 15px;
        font-size: 16px; }

    .mgslider-content {
        h2 {
            font-size: 50px;
            line-height: 50px; }
        p {
            font-size: 18px;
            margin-top: 20px;

            br {
                display: none; } }
        .delemitter {
            display: none; } }

    .banner-slider-clan {
        .clan-slider-content {
            h2 {
                font-size: 40px;
                line-height: 45px; }

            p {
                font-size: 16px; }

            .skew-bg {
                padding: 35px 50px 35px 40px; } } } }

@media (max-width: 768px) {
    #banner, .banner-slider-zommbie {
        height: 85vh; }

    #banner {
        .swiper-wrapper {
            .slider-content {
                h2 {
                    font-size: 35px;
                    line-height: 35px; } } } }

    .section-mini-title {
        h2 {
            &.title {} } }

    .beyond-content {
        .content {
            font-size: 16px; } }

    #banner {
        .banner-slider {
            .slider-content, .slider-content-two {
                h2, {
                    font-size: 35px;
                    line-height: 45px; } }

            .slider-content-two {
                &:after {
                    border-top: 255px solid transparent;
                    border-left: 130px solid rgba(0, 0, 0, 0.83);
                    right: -130px; } } } }

    .mgslider-content {
        width: 65%;

        h2 {
            font-size: 35px;
            line-height: 35px; }
        p {
            font-size: 15px;
            margin-top: 20px;
            line-height: 24px;

            br {
                display: none; } }
        .delemitter {
            display: none; }

        .tim-btn {
            padding: 12px 30px;

            &.tim-btn-bg {
                padding: 12px 40px; } } } }

@media (max-width: 500px) {
    #banner {
        .banner-slider {
            .slider-content, .slider-content-two {
                padding: 30px;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 90%; }

            .swiper-slide {
                height: 85vh; }

            .slider-content-two {
                &:after, &:before {
                    display: none; } } }

        .fire-nav-prev, .fire-nav-next {
            display: none; } }



    .popup-btn {
        i {
            margin-left: 1px; } }

    .mgslider-content {
        &.text-left {
            text-align: center !important;
            width: 80%;
            left: 50%;
            transform: translate(-50%, -50%); } }

    .mgslider-content {
        width: 80%; }


    .banner-slider-clan {
        .clan-slider-content {
            width: 90%;
            h2 {
                font-size: 30px;
                line-height: 30px; }

            p {
                font-size: 14px;
                br {
                    display: none; } }

            .skew-bg {
                padding: 35px 50px 35px 40px;

                &:before, &:after {
                    transform: skewX(0); } } } }

    .mgslider-content {
        h2 {
            font-size: 30px; } } }
