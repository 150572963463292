.shop-three-column-zoombie {
    background-attachment: fixed;
    background-size: cover; }

.site-main {
    .columns-4 {
        ul {
            &.products {
                li {
                    &.product {
                        margin-bottom: 20px;

                        .price {
                            display: inline-block;
                            color: #ffffff;
                            font-weight: 400;
                            float: left;
                            font-size: 20px;
                            font-weight: 500;
                            margin-left: 30px;
                            opacity: 0;
                            visibility: hidden;
                            transition: all 0.3s ease-in-out; }

                        .add_to_cart_button {
                            float: right;
                            color: #adadad;
                            font-weight: 600;
                            font-size: 20px;
                            margin-right: 30px;
                            opacity: 0;
                            visibility: hidden;
                            transition: all 0.3s ease-in-out;
                            border-color: #adadad;

                            &:hover {
                                color: $color_theme-2; } }



                        &:hover {
                            .price, .add_to_cart_button {
                                visibility: visible;
                                opacity: 1; } } } } } } } }

.site-main {
    background: #131313;
    .columns-3 {
        ul {
            &.products {

                li {
                    &.product {
                        margin-bottom: 10px;

                        .product-thumb {
                            height: auto;

                            &:after {
                                top: 20px;
                                left: 16px;
                                right: 22px;
                                bottom: 16px;
                                background-size: cover;
                                background-position: center center;
                                height: auto;
                                width: auto; }


                            img {
                                margin: 0;
                                width: 100%; } }

                        .price {
                            display: inline-block;
                            color: #ffffff;
                            font-weight: 400;
                            float: left;
                            font-size: 20px;
                            font-weight: 500;
                            margin-left: 30px;
                            opacity: 0;
                            visibility: hidden;
                            transition: all 0.3s ease-in-out;
                            margin-bottom: 0; }

                        .add_to_cart_button {
                            float: right;
                            color: #fff;
                            font-weight: 600;
                            font-size: 20px;
                            margin-right: 30px;
                            opacity: 0;
                            visibility: hidden;
                            transition: all 0.3s ease-in-out;

                            &:hover {
                                color: $color_theme-2; } }

                        &:hover {
                            .price, .add_to_cart_button {
                                visibility: visible;
                                opacity: 1; } } } } } } } }

.product-thumb {
    position: relative;
    z-index: 1;
    height: 283px;
    padding: 20px;
    margin-bottom: 20px;
    background: #191919;

    img {
        margin-bottom: 20px; } }

.woocommerce-loop-product__title {
    font-size: 20px !important;
    line-height: 25px;
    color: #adadad;
    font-family: $font-family-primary;
    font-weight: 600 !important;

    &:hover {
        color: $color_theme-2; } }

ul {
    &.products {
        li {
            &.product {
                .star-rating {
                    margin: 0 auto 10px; } } } } }
.star-rating {
    span {
        &:before {
            color: $color_theme-4; } } }

.woocommerce-ordering {
    width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between; }

.woocommerce-result-count {
    font-size: 18px;
    color: #fff;
    margin-right: 20px; }

select {
    &.orderby {
        padding: 4px 10px;
        width: 110px;
        color: #fff;
        background: transparent;
        margin: 0 auto;
        background-color: #2b2b2b;
        border: 1px solid transparent;
        font-size: 16px;
        outline: 0;
        width: 70px; } }


.short {
    select {
        &.orderby {
            width: 180px; } } }

.short, .show-items {
    p {
        color: #fff;
        font-size: 16px;
        color: #fff;
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 0; } }


/* Product Two */
.products {
    &.product-two {
        li.product {
            .product-thumb {
                background: #1a1c20;
                height: auto;
                padding: 0;
                border-radius: 10px;
                text-align: center;
                transition: all 0.3s ease-in-out;

                img {
                    margin-bottom: 20px;
                    width: 100%; }

                &:before, &:before {
                    display: none; }


                &:after {
                    display: none; }
                &:hover {
                    background: #383838; } }

            .woocommerce-loop-product__title {
                margin-bottom: 0; }

            .price, .add_to_cart_button {
                visibility: visible !important;
                opacity: 1 !important; }


            .add_to_cart_button {
                &:after {
                    background: $color_theme-3;

                    &:hover {
                        color: #fff;
                        border-color: $color_theme-3; } } }

            .price {
                margin-left: 0 !important;
                margin-top: 10px;
                font-size: 20px !important;
                font-weight: 600 !important;
                color: $color_theme-2; }

            .add_to_cart_button {
                border: 2px solid #fff;
                padding: 10px 37px;
                line-height: 16px;
                margin-right: 0 !important;

                &:before {
                    background: $color_theme-2; } } } } }

#shop-post-two {
    padding: 100px 0;
    background: #0c0c0c;

    ul.products {
        li.product {
            .star-rating {
                font-size: 14px;
                color: #dedede;
                margin: 5px auto 20px;

                span {
                    &:before {
                        color: #dedede;
                        font-size: 14px; } } } }

        .price {
            color: $color_theme-2; }

        .add_to_cart_button {
            font-size: 18px;
            border-radius: 4px;

            &:after {
                background: $color_theme-2; }

            &:hover {
                color: #fff;
                border-color: $color_theme-2; } } } }


/* Product Single */
.product-slider {
    .slick-slide {
        cursor: pointer;
        img {
            max-width: 100%; } }

    .slider-nav {
        margin-top: 10px; } }

.shop {
    padding: 100px 0; }

.single-product {
    padding: 100px 0 60px;
    background: #13131f;

    .site-main {
        background-color: transparent; } }

.single-product-details {
    .product-title {
        font-size: 32px;
        line-height: 30px;
        margin-bottom: 20px; }

    .price {
        margin-bottom: 50px;
        ins {
            margin-right: 10px;
            text-decoration: none; }

        ins, del {
            font-size: 26px;
            font-weight: 600; } }

    .star-rating {
        span {
            &:before {
                color: $color_theme-2; } } }

    .review-link {
        color: #bdbdbd; }

    .woocommerce-product-details__short-description {
        margin-bottom: 30px; } }


.product-share-link {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;

    li {
        display: inline-block;
        margin-right: 5px;

        a {
            display: block;
            height: 35px;
            width: 35px;
            border-radius: 4px;
            text-align: center;
            line-height: 35px;
            color: #fff;
            transition: all .3s ease-in-out;

            &.facebook-bg {
                background: #305fb3; }

            &.twitter-bg {
                background: #5eaade; }

            &.google-plus-bg {
                background: #d7482a; }

            &.pinterest-bg {
                background: #bd081c; }

            &:hover {
                background: $color_theme-2; } } } }


.rating {
    margin-bottom: 20px;

    li {

        cursor: default;
        display: inline-block;
        font-size: 20px;
        list-style-type: none; } }

.star,
.rating:not(.vote-cast):hover .star:hover ~ .star,
.rating.vote-cast .star.selected ~ .star {
    color: #a5a5a5; }


.rating:hover .star,
.rating.vote-cast .star {
    color: $color_theme-2; }


.share-wrap {
    margin-top: 20px;
    h3 {
        font-size: 18px;
        display: inline-block;
        margin-right: 10px; } }

/* Quick View */
.quickview-wrapper {
    position: fixed;
    width: 800px;
    height: 560px;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%,-60%);
    visibility: hidden;
    backface-visibility: hidden;
    display: inline-block;
    transition: all 0.3s ease-in-out;


    .quickview-content {
        background: #fff;
        padding: 15px;
        transition: all 0.5s ease-in-out; }

    &.open {
        visibility: visible;
        transform: translate(-50%,-50%);
        opacity: 1;

        .quickview-content {
            transform: translateY(0); } } }

.quickview-slider {
    .slider-for, .slider-nav, .slider-for1, .slider-nav1 {
        .slick-slide {
            outline: 0;
            img {
                max-width: 100%; } } }


    .slider-for, .slider-for1 {
        margin-bottom: 15px; }

    .slider-nav, .slider-nav1 {
        .slick-slide {
            cursor: pointer; } } }

/* Product Single */
.product-tab-wrapper {
    .product-tabs {
        margin: 50px 0 30px;
        list-style: none;
        text-align: center;
        border-top: 1px solid #2b2b2b;
        text-align: center;
        display: inherit;


        li {
            display: inline-block;

            a {
                padding: 10px 20px;
                text-decoration: none;
                color: #444;
                display: inline-block;
                transition: all 0.3s ease-in-out;
                border-top: 1px solid transparent;
                font-weight: 600;

                &:hover, &.active {
                    color: $color_theme-2;
                    border-color: $color_theme-2; } } } }
    .tab-content {
        .tab-pane {
            padding: 0; } } }

.comments-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px; }

.review-list {
    margin: 0;
    padding: 0;
    list-style: none;

    .review-comment {
        margin-bottom: 20px;
        .user-avatar {
            height: 70px;
            width: 70px;
            float: left;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
                height: auto; } }

        .comment-content {
            margin-left: 90px;

            .name {
                font-size: 16px;
                font-weight: 700;
                margin: 0; }

            .star-rating {
                span {
                    &:before {
                        color: $color_theme-2; } } }

            .comment-meta {
                margin-bottom: 10px;
                .star-rating {
                    li {
                        font-size: 12px;
                        margin-right: 0; } }

                .post-date {
                    font-size: 12px;
                    color: #a7a7a7; } } } } }


.product-cart {
    margin: 40px 0 60px;

    .quantity {
        position: relative;
        background: #FFF;
        box-shadow: 0 2px 20px rgba(0,0,0,0.09);
        width: 90px;
        height: 50px;
        border: none;
        border-radius: 5px;
        float: left;
        padding: 11px 12px;
        margin-right: 10px;

        .minus, .plus {
            cursor: pointer;
            color: #363636;
            font-size: 12px;

            &:focus {
                background: transparent;
                color: #363636; } }

        input {
            width: 51%;
            margin: 0 auto;
            border: none;
            padding: 0;
            text-align: center;
            font-size: 16px; } }

    .tim-cart-btn {
        background: $color_theme-2;
        padding: 10px 17px;
        color: #FFF;
        border-radius: 4px;
        transition: all 0.3s ease-in-out;
        font-size: 18px;
        font-weight: 500;
        border: 0;
        cursor: pointer;

        i {
            margin-right: 5px; }

        &:hover {
            background: #333;
            color: #FFF; } } }

.rating-title {
    font-size: 22px;
    font-weight: 600; }

.review-form {

    textarea, input {
        border: 1px solid #c9c9c9;
        border-radius: 5px;
        outline: none;
        font-size: 14px;
        width: 100%;
        padding: 10px; }

    textarea {
        height: 150px; }

    input {
        height: 50px;
        margin-top: 20px; }

    .gp-btn {
        max-width: 250px;
        margin: 30px 0  0 15px;
        display: inline-block;
        width: 100%;
        text-align: center;
        background: #333;
        color: #FFF;
        border-radius: 30px;
        padding: 12px 0px;
        font-weight: 500;
        transition: all 0.3s ease-in-out;
        border: none;
        outline: none;
        cursor: pointer;
        text-transform: uppercase;

        &:hover {
            background: $color_theme-2; } } }

.shop_attributes {
    width: 100%;

    tbody {
        border: 1px solid #dcdcdc;
        tr {
            height: 40px;
            border-bottom: 1px solid #dcdcdc;


            th {
                width: 30%;
                border-right: 1px solid #dcdcdc;
                padding-left: 20px; }

            td {
                padding-left: 20px;
                p {
                    margin-bottom: 0; } } } } }

.related-product {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #272727;

    .product-title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 30px;
        color: #b3b3b3; }

    &.site-main {
        .columns-4 {
            ul {
                &.products {
                    li.product {
                        margin-right: 0;
                        width: 25%;
                        padding: 0 15px; } } } } } }

.related-product-slider {
    .product {
        text-align: center;


        .product-thumb {
            padding: 0;
            height: auto;

            img {
                max-width: 100%;
                margin-bottom: 0; } }

        .star-rating {
            margin: 0 auto 15px; }

        .price {
            display: inline-block;
            color: #ffffff;
            font-weight: 400;
            float: left;
            font-size: 20px;
            font-weight: 500;
            margin-left: 30px;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s ease-in-out; }

        .add_to_cart_button {
            float: right;
            color: #adadad;
            font-weight: 600;
            font-size: 20px;
            margin-right: 30px;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s ease-in-out;
            border-color: #adadad;

            &:hover {
                color: $color_theme-2; } }

        &:hover {
            .price, .add_to_cart_button {
                opacity: 1;
                visibility: visible; } } } }


.slick-initialized {
    .slick-slide {
        outline: 0; } }

/* Color Checkbox */
.color-checkboxes {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 30px;

    h4 {
        margin-right: 10px;
        font-size: 18px;
        font-weight: 600; }

    #col-Blue-label {
        background: #2196f3; }

    #col-Green-label {
        background: #8bc34a; }

    #col-Yellow-label {
        background: #fdd835; }

    #col-Orange-label {
        background: #ff9800; }

    #col-Red-label {
        background: #f44336; }

    #col-Black-label {
        background: #222222; } }

.color-checkbox {
    width: 20px;
    height: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-right: 7px;
    position: relative;
    border-radius: 16px;
    -webkit-transition: -webkit-transform 0.1s linear;
    transition: -webkit-transform 0.1s linear;
    transition: transform 0.1s linear;
    transition: transform 0.1s linear, -webkit-transform 0.1s linear;
    cursor: pointer;

    &.bordered {
        -webkit-box-shadow: 0 0 0 2px #f0f0f0 inset;
        box-shadow: 0 0 0 2px #f0f0f0 inset;

        &:after {
            border-color: black; } }

    &:after {
        content: '';
        display: block;
        width: 6px;
        height: 10px;
        position: absolute;
        top: 3px;
        left: 7px;
        border: 2px solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        opacity: 0;
        -webkit-transition: opacity 0.1s;
        -o-transition: opacity 0.1s;
        transition: opacity 0.1s;
        text-align: center; } }

.color-checkbox:hover,
.color-checkbox__input:focus + .color-checkbox {
    -webkit-transform: scale(1.2);
    transform: scale(1.2); }

.color-checkbox__input:checked + .color-checkbox:after {
    opacity: 1; }

.color-checkbox__input {
    visibility: hidden;
    width: 0;
    pointer-events: none;
    position: absolute; }

@media (max-width: 1200px) {
    .site-main {
        .columns-4 {
            ul {
                &.products {
                    li {
                        &.product {

                            .product-thumb {
                                height: auto;

                                &:after {
                                    top: 15px;
                                    left: 13px;
                                    right: 18px;
                                    bottom: 12px;
                                    background-size: cover;
                                    background-position: center center;
                                    height: auto;
                                    width: auto; }


                                img {
                                    margin: 0;
                                    width: 100%; } } } } } } } }

    .site-main {
        .columns-3 {
            ul.products {
                li.product {
                    .product-thumb {
                        &:after {
                            top: 15px;
                            left: 14px;
                            right: 17px;
                            bottom: 13px; } } } } } }
    ul.products li.product {
        margin-bottom: 1.5em; } }

@media (max-width: 992px) {
    .site-main {
        .columns-4 {
            ul.products {
                    li.product {
                        .product-thumb {
                            &:after {
                                top: 10px;
                                left: 11px;
                                right: 11px;
                                bottom: 8px; } } } } } }

    .products {
        &.product-two {
            li.product {
                width: 46% !important;
                margin-right: 3%;
                display: inline-block;
                margin-bottom: 20px; } } }

    .site-main {
        .columns-3 {
            ul.products {
                li.product {
                    .price {
                        margin-left: 8px;
                        font-size: 16px; }

                    .add_to_cart_button {
                        font-size: 16px;
                        margin-right: 7px; } } } } }

    ul.products {
        li.product {
            .star-rating {
                margin-bottom: 0; } } }

    .woocommerce-loop-product__title {
        font-size: 14px !important;
        line-height: 15px;
        font-family: $font-family-primary; }

    .site-main {
        .columns-3, .columns-3 {
            ul.products {
                li.product {
                    .product-thumb {
                        &:after {
                            top: 11px;
                            left: 11px;
                            right: 13px;
                            bottom: 9px; } } } } } } }

@media (max-width: 768px) {
    .site-main {
        .columns-3, .columns-4 {
            ul.products {
                li.product {
                    width: 46%;
                    margin-right: 3%;
                    display: inline-block; } } } }

    .products {
        &.product-two {
            li.product {
                width: 46%; } } }

    .site-main {
        .columns-3, .columns-4 {
            ul {
                &.products {
                    li {
                        &.product {
                            .product-thumb {
                                &:before {
                                    background-size: contain; }
                                &:after {
                                    top: 26px;
                                    left: 24px;
                                    right: 28px;
                                    bottom: 23px; } }

                            .add_to_cart_button {
                                padding: 10px 20px;
                                font-size: 14px;
                                margin-right: 10px; }

                            .price {
                                margin-left: 10px !important; } } } } } } }

    .single-product-details {
        .product-title {
            font-size: 26px;
            margin-top: 30px;
            font-weight: 600; } } }

@media (max-width: 500px) {
    .site-main {
        .columns-4, .columns-3 {
            ul.products {
                li.product {
                    width: 100%;
                    .product-thumb:after {
                        top: 53px;
                        left: 43px;
                        right: 50px;
                        bottom: 46px; } } } } }

    #shop-post-two {
        ul.products {
            li.product {
                .star-rating {
                    margin-bottom: 20px; } } } }

    select {
        &.orderby {
            width: 120px; } }


    .woocommerce-ordering {
        display: block;
        margin-bottom: 30px; }

    .short {
        margin-bottom: 15px; }

    .short, .show-items {
        &.text-right {
            text-align: left !important; } }

    .products {
        &.product-two {
            li.product {
                width: 100% !important; } } } }
