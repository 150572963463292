#screenshot {
    padding-top: 100px;
    // background: #0c0c0c
    background: #181827; }

.sc-title {
    font-size: 30px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px; }

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    backface-visibility: hidden;
    transition: all 0.3s ease-out; }

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1; }

.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8; }


.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0; }

.game-video-item {
    .video-play-btn {
        &.bdr {
            display: inline-block;
            height: 60px;
            width: 60px;
            border: 4px solid;
            border-radius: 50%;
            text-align: center; } } }


@media (max-width: 768px) {
    .torurmant {
        background-size: cover; }

    .countdown {
        .CountdownContent {
            margin-bottom: 2px; } }
    #screenshot {
        .feature-image {
            img {
                width: 100%; } } } }
