#logo-carousel {
    background: #0c0c0c;
    padding: 100px 0; }

#logo-carousel-two {
    background: #1a1c20;
    padding: 100px 0; }

.tim-logo-carousel {
    text-align: center;
    display: flex;
    align-items: center;

    .swiper-wrapper {
        align-items: center; } }
