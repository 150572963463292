#game-tables {
    padding-bottom: 100px;
    background: #131313; }

.tables-inner {
    background: #050404;
    padding: 50px 40px;

    thead {
        background: #1b1717; }

    .table {
        border-collapse: separate;
        border-spacing: 0 8px;
        thead {
            tr {
                th {
                    border: 0;
                    color: #fff;
                    font-size: 18px;
                    font-weight: 500;
                    padding: 16px 10px;

                    &:first-child {
                        padding-left: 30px; } } } }



        td, th {
            border: 0; } }

    tbody {
        tr {
            background-color: #0e0d0d;

            th {
                padding: 25px 0 25px 30px;
                .game-type {
                    color: #fff;
                    font-size: 20px;
                    margin: 0;

                    i {
                        margin-right: 10px; } }

                span {
                    color: #fff;
                    font-size: 14px;
                    font-weight: 300;
                    margin-left: 30px; } }

            td {
                font-size: 18px;
                color: #fff;
                display: table-cell;
                padding: 37px 10px; } } } }


.game-search {
    display: flex;

    input[type="text"] {
        background: #1b1717;
        border: 0;
        color: #fff;
        height: 40px;
        outline: 0;
        padding: 10px;
        max-width: 230px;
        margin-right: 10px;
        width: 100%;

        &::placeholder {
            color: #fff; } }

    .tim-btn-bg {
        background: $color_theme-2;
        border: 0;
        color: #fff;
        height: 40px;
        padding: 10px 30px;
        outline: 0;
        color: #fff;
        border-radius: 0;
        text-transform: capitalize;
        cursor: pointer; } }

.login-form {
    input {
        width: 100%;
        background: #1b1717;
        height: 40px;
        padding: 10px 15px;
        border: 0;
        margin-bottom: 10px;
        outline: 0;
        color: #fff;

        &.check {
            width: auto;
            height: auto;
            background-color: transparent;

            span {
                margin-left: 10px; } } }

    > p {
        color: #fff;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 5px;
        a {
            color: $color_theme-2; } }

    .submit-btn {
        padding: 12px 33px;
        color: #fff;
        text-transform: uppercase;
        background: $color_theme-2;
        display: inline-block;
        border: 0;
        font-size: 20px;
        line-height: 17px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        margin-top: 10px;

        &:hover {
            background: $color_theme-5; } } }


.squaredThree {
    position: relative;
    display: inline-block; }

.squaredThree {
    input[type=checkbox] {
        height: auto;
        width: auto;
        visibility: hidden; } }

.squaredThree {
    label {
        width: 15px;
        height: 15px;
        cursor: pointer;
        position: absolute;
        top: 6px;
        left: 0;
        background: #1b1717; }

    span {
        margin-left: 10px;
        color: #fff; } }

.squaredThree input[type=checkbox]:checked + label:after {
    opacity: 1; }

.squaredThree label:hover::after {
    opacity: 0.3; }

.squaredThree label:after {
    content: '';
    width: 9px;
    height: 5px;
    position: absolute;
    top: 4px;
    left: 4px;
    border: 2px solid #fcfff4;
    border-top: none;
    border-right: none;
    background: transparent;
    opacity: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg); }

.user-widget {
    margin-top: 20px;
    .topics-title {
        color: #fff;
        font-size: 25px;
        margin-top: 0;
        font-weight: 500; }

    .item {
        margin-bottom: 10px;
        p {
            margin-bottom: 0;
            color: #b5b5b5;
            line-height: 16px;
            position: relative;
            overflow: hidden;
            text-decoration: underline;

            a {
                color: $color_theme-2; } }

        span {
            color: #888; }

        a {
            color: $color_theme-2; } } }

@media (max-width: 991px) {
    .tables-inner {
        padding: 35px 25px; }

    .tables-inner {
        tbody {
            tr {
                th {
                    padding: 20px 0 20px 20px; } } }

        .table {
            thead {
                tr {
                    th {
                        &:first-child {
                            padding-left: 20px; } } } } } } }

@media (max-width: 500px) {

    .game-search input[type="text"] {
        margin-right: -3px; }

    .tables-inner {
        tbody {
            tr {
                th {
                    .game-type {
                        font-size: 16px; }

                    span {
                        margin-left: 0;
                        display: block;
                        line-height: 20px; } } } }

        .table {
            thead {
                tr {
                    th {
                        font-size: 15px; } } } } }

    .tables-inner {
        padding: 30px 20px;

        tbody {
            tr {
                th {
                    padding: 15px 0 25px 15px; }

                td {
                    text-align: center; } } } } }
