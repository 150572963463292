#tranding-game {
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;


    .section-title {
        .section-content {
            p {
                margin-bottom: 30px; } } } }


.rating-with-social {
    h3 {
        font-size: 30px;
        font-weight: 700;
        color: #fff;
        margin: 0; }

    .review {
        li {
            font-size: 20px; } } }

.social-link {
    li {
        display: inline-block;
        margin-right: 10px;
        a {
            color: #fff;
            font-size: 18px; } } }

.win-dwn-btn {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    display: inline-block;
    margin-top: 40px;

    img {
        margin-right: 10px; }

    &:hover {
        color: $color_theme-4; } }

@media (max-width: 500px) {
    .rating-with-social {
        h3 {
            font-size: 20px; } }

    .rating-with-social {
        .review {
            li {
                font-size: 15px; } } }

    .social-link {
        li {
            a {
                font-size: 13px; } } } }
