
.review-inner {
    padding: 20px;
    background-size: cover; }

.review-slider {
    position: relative;

    .swiper-slide {
        img {
            max-width: 100%;
            width: 100%; } }

    #review-nav-prev, #review-nav-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 100;
        height: 30px;
        width: 30px;
        line-height: 30px;
        color: #fff;
        background: rgba(0,0,0,0.58);
        border-radius: 4px;
        text-align: center;
        cursor: pointer; } }


#review-nav-prev {
    left: 15px; }

#review-nav-next {
    right: 15px; }

.revied-gread {
    background: #0b0c10;
    text-align: center;
    padding: 40px 10px;

    .gread {
        height: 100px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        border: 7px solid rgba(255,255,255,0.5);
        border-radius: 50%;
        margin-bottom: 20px;

        h3 {
            font-size: 50px;
            margin: 0;
            font-weight: 700;
            color: $color_theme-2;
            line-height: 35px;
            margin-top: 5px; } }

    .review {
        margin-bottom: 50px;
        li {
            font-size: 20px;
            color: $color_theme-2; } }

    .review-text {
        color: #fff;
        font-size: 16px;
        line-height: 25px;
        color: $color_theme-2;

        i {
            color: #fff; }


        .fa-quote-left {
            margin-right: 5px; }

        .fa-quote-right {
            margin-left: 5px; } }

    .avatar {
        height: 55px;
        width: 55px;
        border: 5px solid rgba(255,255,255,0.5);
        border-radius: 50%;
        text-align: center;
        margin: 0 auto;

        img {
            border-radius: 50%;
            width: 100%; } } }

.game-details-wrap {
    display: flex; }

.game-tips-inner {
    hr {
        margin: 30px 0 50px;
        border-bottom: 1px solid #252525; }

    .tips-bg {
        background: rgba(0,0,0,0.5);
        padding: 50px 40px;
        border-radius: 10px; } }

.game-details {
    margin-top: 20px;
    width: 50%;
    float: left;
    h4 {
        color: #fff;
        font-size: 20px;
        margin: 0;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 15px; }

    .gfeature {
        margin: 0;
        padding: 0;
        list-style: none;
        float: left;

        li {
            color: #fff;
            font-size: 16px;
            line-height: 25px;
            position: relative;
            margin-left: 20px;
            font-weight: 400;

            &:before {
                position: absolute;
                content: "\f05d";
                font: normal normal normal 14px/1 FontAwesome;
                left: -18px;
                top: 4px; } } } }

/* Game Tips */
.game-tips {

    h3 {
        font-size: 30px;
        line-height: 25px;
        font-weight: 500;
        color: #dedede;
        margin-bottom: 30px; }

    p {
        color: #bfbfbf;
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;

        &:first-child {
            margin-bottom: 30px; } }

    ul.tips-item {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-bottom: 30px;

        li {
            font-size: 16px;
            line-height: 28px;
            font-weight: 500;
            margin-left: 25px;
            color: #bfbfbf;
            position: relative;

            &:after {
                position: absolute;
                content: "\f05d";
                font: normal normal normal 14px/1 FontAwesome;
                left: -20px;
                top: 5px;
                color: $color_theme-3; } } }

    .game-large-player {
        .youtube-wrapper {
            padding-bottom: 56.3%;

            .youtube-poster {
                height: 411px; } } } }

.widgets-titleb {
    font-size: 30px;
    font-weight: 500;
    color: #dedede;
    margin: 0 0 20px; }

.widgets-title {
    font-size: 25px;
    color: #dedede;
    margin-top: 0;
    margin-bottom: 20px; }

.feature-video-sm {
    position: relative;
    background: #252525;
    padding: 10px;
    border-radius: 10px;

    img {
        border-radius: 10px;
        width: 100%; }

    .popup-btn-three {
        height: 50px;
        width: 50px;
        line-height: 52px;
        border-radius: 50%;
        background: $color_theme-2;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: 20px;
        outline: 0;

        i {
            color: #dedede;
            margin-left: 3px; } } }

.button-wrap {
    .popup-btn-two {
        padding: 14px 40px;
        text-transform: uppercase;
        font-size: 20px;
        background: $color_theme-7;
        line-height: 20px;

        i {
            margin-right: 8px; }

        &:hover, &:focus {
            background: $color_theme-5; } } }

.content-with-image {
    margin-bottom: 50px;
    img {
        float: left;
        max-width: 340px;
        height: auto; }

    .content {
        margin-left: 360px;

        p {
            color: #bfbfbf;
            font-size: 16px;
            line-height: 28px;
            font-weight: 500; } } }


@media (min-width: 769px) and (max-width:1200px) {
    .game-details {

        .gfeature {
            margin: 0 10px 0 0;

            li {
                font-size: 14px; } } }

    .game-blog-post {
        margin-top: 20px; }

    .revied-gread {
        .review-text {
            font-size: 14px; }

        .review {
            margin-bottom: 20px; } } }


@media (max-width: 768px) {
    .review-inner {
        .pr__15 {
            padding-right: 0 !important; } }
    .game-details {
        margin-bottom: 20px; }

    .content-with-image {
        img {
            float: none;
            margin-bottom: 10px; }

        .content {
            margin-left: 0; } }

    .review-inner {
        margin-bottom: 20px; } }

@media (max-width: 500px) {
    .review-inner {
        margin-bottom: 30px;
        background-position: center;
        background-size: cover; }

    .game-details-wrap {
        display: block;

        .gfeature {
            width: 100%;
            margin-bottom: 20px; } }

    .game-details {
        width: 100%;
        margin-bottom: 0;

        .gfeature {
            li {
                font-size: 18px;
                line-height: 30px; } } } }
