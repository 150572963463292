#game-strategy {

    .section-icon-title {
        padding: 25px 20px; } }

.tim-tab-nav-two {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 20px;

    li {
        display: inline-block;
        text-align: center;
        width: 50%;
        float: left;

        a {
            padding: 21px 0;
            color: #fff;
            display: inline-block;
            width: 100%;
            background: #272737;
            font-size: 18px;
            font-weight: 500;
            text-transform: uppercase;

            &:hover, &.active {
                background: $color_theme-2; } } } }

@keyframes fadeIn {
    from {
        opacity: 0;
        // transform: translate3d(20px, 0, 0);
 }        // -webkit-transform: translate3d(20px, 0, 0);

    to {
        opacity: 1;
        // transform: translate3d(0, 0, 0);
 } }        // -webkit-transform: translate3d(0, 0, 0)


.tab-content {
    .tab-pane {
        &.active {
            animation: fadeIn .75s ease forwards; } } }


.latest-post {
    &.stategy-small {
        text-align: center;
        .blog-content {
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            bottom: auto;

            &:after {
                position: absolute;
                content: '';
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                background: rgba($color_theme-2, 0);
                transition: all 0.3s ease-in-out; }


            .content {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                left: 0;
                height: auto;
                z-index: 2;

                .blog-title {
                    margin-top: 0; } } }

        &:hover {
            .blog-content {
                    &:after {
                        background: rgba($color_theme-2, 0.7); } } } }

    &.stategy-mid {
        &.stategy {
            .blog-title {
                font-size: 25px;
                line-height:  30px; } } } }

@media (max-width: 500px) {
    .game-tips {
        .cart-btn-two, .button-wrap .popup-btn-two {
            padding: 12px 15px;
            font-size: 14px; } }

    .content-with-image {
        img {
            width: 100%; } }

    .game-tips {
        p {
            &:first-child {
                margin-bottom: 10px; } } }

    .section-icon-title {
        .float-left {
            float: none !important;
            margin-bottom: 10px; } }

    .tim-tab-nav {
        li {
            a {
                margin-right: 10px; } } } }


