.tim-tab-nav {
    li {
        display: inline-block;
        line-height: 20px;

        a {
            color: #fff;
            font-size: 18px;
            font-weight: 500;
            position: relative;
            margin-right: 35px;

            &:hover, &.active {
                color: $color_theme-2; } } } }

#live-stream {
    background-size: cover;
    background-position: center center;

    .tim-tab-nav {
        margin-bottom: 20px;
        li {
            a {
                padding-bottom: 16px;

                &:after {
                    position: absolute;
                    content: '';
                    left: 0;
                    bottom: 0;
                    width: 0;
                    height: 2px;
                    background: $color_theme-2;
                    transition: all 0.3s ease-in-out; }

                &:hover,  &.active {
                    color: #FFF;
                    &:after {
                        width: 40px; } } } } } }


.game-video-item {
    overflow: hidden;
    position: relative;

    .blog-content {
        position: absolute;
        left: 20px;
        bottom: 20px; }

    .video-play-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: #FFF;
        font-size: 50px;
        outline: none; } }

.game-height {

    .feature-image {
        img {
            width: auto;
            height: 193.5px; } } }

.youtube-banner {
    height: 100%;
    overflow: hidden; }

.youtube-wrapper {
    position: relative;
    padding-bottom: 59.7%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background-color: #191a1c;
    cursor: pointer;


    &:before {
        content: "\f144";
        font-family: 'FontAwesome';
        font-size: 50px;
        margin-top: 7px;
        margin-left: 3px;
        position: absolute;
        top: 50%;
        left: 50%;
        color: #fff;
        z-index: 2;
        cursor: pointer;
        visibility: visible;
        transform: translate3d(-50%, -50%, 0) scale(1);
        -moz-transform: translate3d(-50%, -50%, 0);
        -webkit-transform: translate3d(-50%, -50%, 0);
        transition: transform .2s, opacity .2s;
        -moz-transition: transform .2s, opacity .2s;
        -webkit-transition: transform .2s, opacity .2s; }

    &.reveal:after, &.reveal:before, &.reveal .blog-content {
        opacity: 0;
        visibility: hidden;
        transition: visibility .4s, opacity .4s; }

    &.reveal {
        iframe {
            visibility: visible; } }

    iframe,object, embed {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: none;
        visibility: hidden; } }

.youtube-wrapper {
    .youtube-poster {
        height: 387px;
        background-position: center;
        background-size: cover; } }



@media (max-width: 1024px) {
    .game-height {
        .feature-image {
            img {
                height: auto; } } } }


@media (max-width: 991px) {
    .game-video-item {

        .youtube-wrapper {
            padding-bottom: 57.2%; } } }

@media (max-width: 768px) {
    .game-video-item {
        margin-bottom: 20px;

        .youtube-wrapper {
            padding-bottom: 57.2%; } }

    .game-height {
        .feature-image {
            img {
                width: 100%;
                height: auto; } } }

    .tim-tab-nav {
        li {
            a {
                margin-right: 10px; } } } }
