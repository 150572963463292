body {
    font-family: $font-family-primary;
    font-size: 16px;
    line-height: 28px;
    color: #c4c4c4; }

a {
    transition: all 0.15s ease-in-out; }
a,
a:hover {
    text-decoration: none; }

ul,ol {
    padding: 0;
    margin: 0;
    list-style: none; }

/* Reset */
.pr {
    position: relative !important; }

.bdn {
    border: none !important; }

/* Padding */
.pr__15 {
    padding-right: 15px !important; }

.pl__15 {
    padding-left: 15px; }

.pt__15 {
    padding-top: 15px !important; }

.pt__50 {
    padding-top: 50px !important; }

.pt__80 {
    padding-top: 80px !important; }

.pt__100 {
    padding-top: 100px !important; }

.pb__100 {
    padding-bottom: 100px !important; }

.pb__50 {
    padding-bottom: 50px !important; }

.pt__30 {
    padding-top: 30px; }

.pb__30 {
    padding-bottom: 30px !important; }

/* Margon */
.mr__0 {
    margin-right: 0 !important; }

.mb_10, .mb__10 {
    margin-bottom: 10px; }

.mb__15 {
    margin-bottom: 15px; }

.mb__17 {
    margin-bottom: 17px; }

.mb__20 {
    margin-bottom: 20px !important; }

.mb__25 {
    margin-bottom: 25px; }

.mb__30 {
    margin-bottom: 30px; }

.mb__50 {
    margin-bottom: 50px; }

.mt_20 {
    margin-top: 20px; }

.mt__30 {
    margin-top: 30px; }

.mt_40 {
    margin-top: 40px; }

.mt__50 {
    margin-top: 50px; }

.brn {
    border: 0 !important; }

.section-padding {
    padding: 100px 0; }


/* Background */
.bgb {
    background: #000; }

.content-two {
    margin-top: 20px; }

.section-padding {
    padding: 100px 0; }

.parallax {
    background-size: cover; }


/* Section Title */
.section-title {
    text-align: center;
    margin-bottom: 70px;

    .title, .sub-title {
        text-transform: uppercase;
        margin: 0;
        font-weight: 700; }

    .title {
        font-size: 50px;
        position: relative;
        color: #dedede;
        margin-bottom: 10px;

        span {
            color: $color_theme-2; } }

    .sub-title {
        font-size: 20px;
        color: $color_theme-2; }

    .delemitter {
        display: block;
        height: 50px;
        background-repeat: no-repeat;
        max-width: 237px;
        margin: 0 auto 20px; }

    .section-content {
        p {
            max-width 700px {}
            margin: 0 auto;
            font-size: 18px;
            line-height: 28px; } } }

.section-title-two {
    text-align: center;
    margin-bottom: 60px;

    .title {
        font-size: 30px;
        line-height: 25px;
        color: #dedede;
        font-weight: 600;
        position: relative;
        overflow: hidden;
        margin: 0;

        &:before, &:after {
            position: absolute;
            content: '';
            height: 1px;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            background: #353558;
            max-width: 400px; }

        &:before {
            left: 0; }

        &:after {
            right: 0; } } }

.section-mini-title {
    margin-bottom: 50px;
    position: relative;

    .title, .sub-title {
        color: #dedede; }



    .title {
        font-size: 30px;
        position: relative;
        font-weight: 700;
        margin-bottom: 0; }


    .sub-title {
        font-size: 25px;
        color: $color_theme-2;
        font-weight: 700; } }

.section-icon-title {
    background-image: $color_gradian;
    padding: 23px 20px;
    margin-bottom: 20px;
    position: relative;

    &:before {
        content: '';
        background: url(../../media/background/stripe.png) top left;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0; }

    h3 {
        margin: 0;
        color: #FFF;
        font-size: 20px;
        position: relative;


        i {
            margin-right: 10px; } } }

.section-title-three {
    text-align: center;
    margin-bottom: 30px;
    h2 {
        font-size: 40px;
        line-height: 45px;
        font-weight: 600;
        color: #fff;
        margin: 0; } }


/* Button */
.cart-btn {
    font-size: 20px;
    line-height: 25px;
    font-weight: 500;
    background: $color_theme-2;
    border: 2px solid #fff;
    color: #fff;
    display: inline-block;
    padding: 11px 31px;
    border-radius: 4px;
    text-transform: uppercase;

    i {
        margin-right: 8px; }

    &:hover, &:focus {
        color: #fff;
        background: $color_theme-4;
        border-color: $color_theme-4; } }

.cart-btn-two {
    padding: 14px 49px;
    text-transform: uppercase;
    background: $color_theme-3;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    vertical-align: middle;
    border-radius: 4px;
    display: inline-block;

    i {
        margin-right: 8px; }

    &:hover, &:focus {
        background: $color_theme-6;
        color: #fff; } }

.details-btn {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    display: inline-block;
    padding: 5px 16px;
    background: rgba(255,255,255,0.302);
    border-radius: 4px;
    line-height: 17px;
    border: 1px solid #fff;

    &:hover, &:focus {
        background: $color_theme-4;
        color: #fff;
        border-color: $color_theme-4;
        outline: 0; } }

.popup-btn-two {
    background: $color_theme-5;
    color: #fff;
    padding: 8px 25px;
    border-radius: 4px;
    font-weight: 600;
    display: inline-block;

    i {
        margin-right: 5px; }

    &:hover, &:focus {
        color: #fff;
        background: $color_theme-2;
        outline: 0; } }

.follow-btn {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    display: inline-block;
    padding: 5px 12px;
    background: $color_theme-2;
    border-radius: 3px;
    line-height: 16px;

    i {
        margin-right: 5px; }

    &:hover, &:focus {
        background: darken($color_theme-2, 10%);
        color: #fff;
        outline: 0; } }

.play-btn {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;

    i {
        font-size: 30px;
        margin-right: 10px;
        vertical-align: middle; }

    &:hover {
        color: $color_theme-4; } }

.home-zoombie {
    background-attachment: fixed; }

.home-version-magazine {
    background: #13131f; }


.home-clan {
    // background: #141416
    background: #13131f; }

@media all and (max-width: 1600px) {
    .home-clan {
        background-size: cover;
        background-position: center; } }

@media all and (max-width: 1200px) {
    #fire-title {
        font-size: 40px;
        overflow: hidden;
        display: inline-block; }

    #fire-bg {
        canvas {
            width: 385px;
            margin-top: 2px; } }

    .section-title-two {
        .title {
            &:before, &:after {} } }

    .section-title-two .title {
        &:before, &:after {
            width: 200px; } } }

@media (max-width: 1024px) {
    .section-title {

        h2 {
            &.title {
                font-size: 35px; } }

        h3 {
            &.sub-title {
                font-size: 18px; } }

        .delemitter {
            max-width: 170px;
            background-size: contain;
            margin-bottom: 15px; }

        .section-content {
            p {
                font-size: 16px;

                br {
                    display: none; } } } }

    #fire-title {
        font-size: 30px; }

    #fire-bg {
        canvas {
            width: 290px;
            margin-top: 1px; } } }

@media all and (max-width: 991px) {
    .rmt__30 {
        margin-top: 30px; } }

@media all and (min-width: 767px) {
    .padd__none {
        padding: 0 !important; }

    .section-title-three {
        font-size: 30px; } }


@media (max-width: 768px) {
    .section-title {
        margin-bottom: 50px;

        h2 {
            &.title {
                font-size: 30px; } }

        .section-content {
            p {
                br {
                    display: none; } } } }

    .section-padding {
        padding: 50px 0; }

    .section-title-two {
        .title {
            font-size: 25px;
            &:before, &:after {
                width: 100px; } } }

    #fire-bg {
        width: 60%;
        top: 3px; }

    .rsmt__30 {
        margin-top: 30px; } }

@media (max-width: 500px) {
    .section-title-two {
        .title {
            &:before, &:after {
                width: 70px; } } }

    #fire-title {
        font-size: 28px; }

    #fire-bg {
        canvas {
            width: 270px;
            margin-top: 3px; } }

    .col-full-width {
        flex: 0 0 100% !important;
        max-width: 370px;
        margin: 0 auto; } }


.tim-btn, .products.product-two li.product .add_to_cart_button {
    padding: 10px 20px;
    font-size: 18px;
    color: #dedede;
    background: transparent;
    border: 2px solid #dedede;
    text-transform: uppercase;
    line-height: 18px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    display: inline-block;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:before, &:after {
        background: $color_theme-2;
        content: '';
        position: absolute;
        z-index: -1; }

    &:after {
        height: 100%;
        left: -60%;
        top: 0;
        transform: skew(50deg);
        transition-duration: 0.4s;
        transform-origin: top left;
        width: 0; }

    &:hover {
        border-color: $color_theme-2;
        color: #fff;
        &:after {
            height: 100%;
            width: 170%; } } }

.tim-btn {
    font-weight: 500;
    &.btn-sm {
        padding: 8px 17px;
        font-size: 16px; }

    &.btn-larg {
        padding: 12px 30px; }

    &.btn-exlg {
        padding: 16px 35px; } }


.tim-btn-bg {
    padding: 14px 25px;
    background: $color_theme-2;
    display: inline-block;
    border: 2px solid #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 17px;
    color: #fff;
    border-radius: 4px;
    text-transform: uppercase;

    i {
        margin-left: 5px; }

    &:hover {
        color: #fff;
        background: $color_theme-6;
        border-color: $color_theme-6; } }



/* Flat Icons */
@include font-face(Flaticon, '../fonts/Flaticon', normal);


[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-style: normal; }

.flaticon-man-with-bag-and-walking-stick {
    &:before {
        content: "\f100"; } }

.flaticon-trophy {
    &:before {
        content: "\f101"; } }

.flaticon-fire {
    &:before {
        content: "\f102"; } }

.flaticon-gamepad {
    &:before {
        content: "\f103"; } }
