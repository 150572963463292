#page-header {
    height: 485px;
    background-size: cover;
    background-position: center center; }

.page-title-wrap {
    position: relative;
    height: 485px;

    .page-title-inner {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;
        left: 0;

        h1 {
            color: #fff;
            font-size: 40px;
            margin-bottom: 0;
            font-weight: 700;
            text-transform: uppercase;

            span {
                color: $color_theme-2; } }

        h3 {
            color: #fff;
            font-size: 20px;
            margin-bottom: 0; }


        &:after {
            position: absolute;
            content: '';
            background-image: url("../../"); }

        .delemitter {
            display: block;
            height: 50px;
            background-repeat: no-repeat;
            max-width: 237px;
            margin: 0 auto; } } }

@media (max-width: 991px) {
    #page-header {
        height: 200px;
        padding-top: 0; }

    .page-title-wrap {
        height: 200px;

        h1 {
            font-size: 35px; } } }
