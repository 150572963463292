
.add-big-wrap {
    position: relative;

    img {
        width: 100%; }

    .adds-content {
        position: absolute;
        left: 0;
        top: 0;
        width: 80%;
        height: 100%;

        .content {
            position: absolute;
            top: 50%;
            left: 70px;
            transform: translateY(-50%); }

        .add-title {
            a {
                color: #fff;
                font-size: 30px;
                line-height: 40px;
                font-weight: 700;
                margin-top: 0;

                &:hover {
                    color: $color_theme-2; } } }

        .popup-btn {
            font-size: 16px;
            font-weight: 600;
            padding: 8px 28px;

            i {
                background: transparent;
                height: auto;
                width: auto;
                color: #fff; } } } }

.add-detail {
    position: absolute;
    right: 0;
    top: 0;
    width: 30%;
    height: 100%;
    text-align: right;
    padding: 10px 30px 30px 0;

    .add-batch {
        background: #000;
        color: #fff;
        font-size: 14px;
        padding: 0 9px;
        line-height: 20px;
        display: inline-block; }

    .add-size-title {
        font-size: 20px;
        font-weight: 700;
        color: #fff;
        margin: 25px 0 0; }

    .advertize {
        color: #fff;
        position: absolute;
        left: 20px;
        right: 30px;
        font-weight: 700;
        bottom: 20px;
        margin: 0;
        font-size: 14px; } }


.add-small {
    position: relative;

    img {
        max-width: 100%; }

    .adds-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 10px 10px 20px 20px;

        .add-batch {
            background: rgba(0,0,0,0.2);
            color: #fff;
            font-size: 14px;
            padding: 0 9px;
            line-height: 20px;
            display: inline-block; }

        .add-title {
            font-size: 25px;
            line-height: 30px;
            margin-top: 30px;

            a {
                color: #fff;
                margin: 0;
                font-weight: 700;

                &:hover {
                    color: $color_theme-2; } } }


        .add-detail {
            width: 100%;
            bottom: 0;
            text-align: left; }

        .popup-btn {
            padding: 6px 17px;
            font-size: 16px;

            i {
                background: transparent;
                color: #fff;
                height: auto;
                width: auto; } }

        .add-size-title {
            color: #fff;
            font-weight: 700;
            font-size: 18px;
            margin-top: 30px; } } }

@media (min-width: 768px) and (max-width: 1024px) {
    .add-small {
        .adds-content {
            padding: 10px;
            .add-title {
                font-size: 15px;
                line-height: 20px; }

            .popup-btn {
                padding: 6px 10px;
                font-size: 14px;
                margin-top: 0; }

            .add-size-title {
                font-size: 12px;
                margin-top: 7px; } } }


    .popup-btn {
        margin-top: 10px; }

    .add-big-wrap {
        .adds-content {
            .add-title {
                line-height: 25px;
                a {
                    font-size: 22px;
                    line-height: 25px; } } } } }


@media (max-width: 768px) {
    .add-big-wrap {
        margin-bottom: 10px;
        &.pr__15 {
            padding-right: 0 !important; }
        img {
            height: 200px;
            width: 100%; }
        .adds-content {
            .content {
               left: 30px; }

            .add-title {
                line-height: 25px;
                a {
                    font-size: 20px;
                    line-height: 25px; } } } }

    .add-small {
        height: 250px;
        img {
            height: 100%;
            width: auto; }
        .adds-content {
            .add-title {
                font-size: 18px;
                line-height: 25px;
                margin-top: 10px; } }

        .float-right {
            float: none !important; } } }

@media (max-width: 500px) {
    .add-big-wrap {
        .adds-content {
            .add-title {
                br {
                    display: none; } } } }

    .add-big-wrap {
        overflow: hidden;
        margin-bottom: 20px;

        img {
            height: 100%;
            width: auto; }

        .adds-content {
            width: 100%;
            .content {
                position: static;
                left: 35px;
                transform: translate(0);
                padding: 10px; } } }

    .add-detail {
        width: 100%;
        height: 100px;
        bottom: 0;
        top: auto;
        text-align: left;

        .add-size-title {
            margin-left: 10px;
            margin-top: 0; }

        .advertize {
            left: 10px; } } }
