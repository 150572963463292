#beyond {
    padding: 100px 0 70px;
    background-size: cover;
    background-position: center center;
    background: #181827; }

#beyond-two {
    background-size: cover;
    background-attachment: fixed; }

.beyond-content {
    .content {
        font-size: 16px; } }

.game-items {
    .game-item {
        text-align: center;
        margin-bottom: 30px;

        img {
            border-radius: 5px;
            transition: all 0.3s ease-in;
            width: 100%; }

        h3 {
            color: #FFF;
            font-size: 20px;
            margin-top: 20px;
            overflow: hidden;
            display: inline-block;
            line-height: 20px;
            transition: all 0.3s ease-in; }

        &:hover {
            img {
                box-shadow: 0 0 50px rgba($color_theme-2, 0.6); }

            h3 {
                box-shadow: 0 0 50px rgba($color_theme-2, 0.6); } } } }

.game-items {
    .game-item {
        &.hover-shadow {
            &:hover {
                img {
                    box-shadow: 0 0 40px rgba(255,255,255,0.5); }
                h3 {
                   box-shadow: 0 0 20px rgba(255,255,255,0.5); } } } } }




@media (max-width: 768px) {
    #beyond {
        padding: 50px 0; } }
