.news_letter {
    padding: 50px 0;

    &.section-padding {
        padding: 30px 0 80px; } }

.newsletter-wrap {
    padding: 50px;
    .newsletter-content {
        // padding-bottom: 20px
        .newsletter-title {
            color: #fff;
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 0; }

        p {
            color: #fff;
            margin-bottom: 0; } } }


.newsletter-form-wrap {
    padding: 25px 0;
    .news-form {
        input {
            height: 46px;
            max-width: 280px;
            width: 100%;
            padding: 0 10px;
            border: 2px solid #fff;
            background: transparent;
            color: #fff;
            margin-right: 4px;
            outline: 0;

            &::placeholder {
                color: #fff; } }

        button {
            width: 120px;
            text-align: center; } } }

.nwl-title {
    h2 {
        color: #fff;
        font-size: 30px; }

    p {
        color: #fff;
        margin-bottom: 25px; } }


#newsletter-two {
    background: #0c0c0c;
    padding: 100px 0; }

#nsletter-two {
    max-width: 570px;
    margin: 0 auto;


    input {
        width: 100%;
        background: #000;
        color: #fff;
        height: 50px;
        border: 0;
        outline: 0;
        padding: 10px 20px;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;

        &::placeholder {
            color: #bababa; } } }

.nwl-btn {
    padding: 10px;
    width: 140px;
    font-size: 20px;
    text-transform: uppercase;
    color: #fff;
    background: $color_theme-3;
    border: 0;
    //border-radius: 5px
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    outline: 0;
    cursor: pointer;

    &:after {
        left: -60px; }

    i {
        display: none; } }

#newsletter-two {
    overflow: hidden; }

@media (max-width: 1024px) {
    .newsletter-wrap {
        .newsletter-content {
            .newsletter-title {
                font-size: 25px; } } } }

@media (max-width: 991px) {
    #nsletter-two {
        input {
            border: 1px solid #1d1d1d; } } }

@media (max-width: 768px) {
    .newsletter-wrap {
        padding: 30px; }

    .newsletter-form-wrap {
        padding: 20px 0 0; } }


@media (max-width: 500px) {

    .news_letter {
        padding: 15px; }

    .newsletter-wrap {
        .newsletter-content {
            .newsletter-title {
                font-size: 18px;
                line-height: 20px;
                margin-bottom: 10px; } } }

    .newsletter-wrap {
        .newsletter-content {
            p {
                line-height: 20px; } } }

    .news-form {
        text-align: center;
        input {
            margin-bottom: 10px; }
        &.d-flex {
            display: block !important; } }


    .newsletter-wrap {
        padding: 20px 6px; }

    #nsletter-two {
        &.d-flex {
            display: block !important; }

        input {
            margin-bottom: 10px;
            border-radius: 5px; }

        .nwl-btn {
            border-radius: 5px;
            padding: 13px; } } }


