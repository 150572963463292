#blog {
    position: relative; }

.blog-post {
    .feature-image {
        position: relative;
        overflow: hidden;
        box-shadow: 0 20px 40px rgba(0,0,0,0.5);
        border-radius: 12px;

        img {
            border-radius: 12px; } }


    .blog-title {
        font-size: 25px;
        margin-top: 15px; }


    .game-cat {
        position: absolute;
        left: 20px;
        bottom: 10px;

        .cat-1, .cat-2 {
            padding: 0px 10px;
            font-size: 14px;
            font-weight: 700;
            display: inline-block;
            border-radius: 4px;
            color: #fff; }

        .cat-1 {
            background: $color_theme; }


        .cat-2 {
            background: #297c09; } } }

.blog-content {
    .blog-title {
        font-size: 22px;
        font-weight: 700; }

    .content {
        font-size: 16px;
        line-height: 28px; } }

.blog-title {
    color: #FFF;
    font-weight: 700;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    a {
        color: #ddd;

        span {
            color: $color_theme-2; }

        &:hover {
            color: $color_theme-2; } } }

.read-btn {
    font-size: 16px;
    color: #FFF;
    font-weight: 500;
    padding: 11px 18px;
    display: inline-block;
    border: 1px solid #fff;
    border-radius: 4px;

    i {
        margin-left: 5px; }

    &:hover {
        background: rgba($color_theme, 0.7);
        color: #ededed; } }

.feature-image {
    position: relative;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    img {
        max-width: 100%;
        transition: all 0.3s ease-in-out; }

    &:hover {
        img {
            transform: scale(1.1) rotate(3deg); } } }

.fire-nav-prev, .fire-nav-next {
    height: 45px;
    width: 45px;
    line-height: 57px;
    text-align: center;
    // border: 2px solid #fff
    border-radius: 50%;
    position: absolute;
    top: 65%;
    transform: translateY(-50%);
    cursor: pointer;
    outline: 0;
    z-index: 111;

    &:before {
        position: absolute;
        content: '';
        top: 4px;
        left: 2px;
        height: 41px;
        width: 41px;
        border: 2px solid #5b5b5b;
        border-radius: 50%; }

    i {
        color: #5b5b5b;
        font-size: 30px; }

    canvas {
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%; } }

#blog-nav-next {
    right: 15%; }

#blog-nav-prev {
    left: 15%; }

.navigation {
    margin-bottom: 60px; }

/* Blog Post Two */
.blog-post-two {
    .feature-image {

        .blog-content {
            position: absolute;
            left: 0;
            bottom: 15px;
            padding: 0 20px;

            .blog-title {
                font-size: 20px;
                line-height: 22px;
                margin-top: 10px;
                margin-bottom: 0; } }

        .meta {
            li {
                margin-right: 0; } } }

    &.blog-large {
        .feature-image {
             .blog-content {
                .blog-title {
                    font-size: 22px;
                    line-height: 30px;
                    margin-bottom: 10px; } } } }

    &:hover {
        .feature-image {
            img {
               transform: scale(1.1) rotate(3deg); } } } }

.cat {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;

        a {
            display: inline-block;
            border: 1px solid #fff;
            color: #FFF;
            padding: 0 16px;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            line-height: 28px;
            margin-right: 10px;

            &:hover {
                border-color: $color_theme-2;
                &:after {
                    background: $color_theme-2; } } } } }

.meta {
    li {
        display: inline-block;
        margin-right: 10px;

        span {
            color: #fff; }
        a {
            font-size: 16px;
            color: #fff;
            margin-right: 5px;

            i {
                margin-right: 5px; }

            &:hover {
                color: $color_theme-2;

                i {
                    color: #FFF; } }

            &.admin {
                font-weight: 600;
                margin-right: 5px;
                position: relative;

                &:after {
                    position: absolute;
                    content: '';
                    right: -6px;
                    bottom: 7px;
                    height: 3px;
                    width: 3px;
                    background: #fff;
                    border-radius: 50%; } } } } }

.meta {
    &.meta-grey {
        li {
            a {
                color: #888;

                &:after {
                    background: #888; }

                &:hover {
                    color: $color_theme-2; } } } } }

/* Blog Post Three */
#blog-two {
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0; }


.blog-slider {
    position: relative;

    .slider-nav-prev {
        left: -10%; }

    .slider-nav-next {
        right: -10%; } }

.blog-post-three {
    padding: 10px;
    overflow: hidden;
    border-radius: 10px;
    background: #1a1c20;

    .feature-image {
        border-radius: 10px;
        overflow: hidden; }

    .blog-content {
        padding: 20px 10px;

        .blog-title-two {
            font-size: 22px;
            font-weight: 700;

            a {
                color: #dedede;


                &:hover {
                    color: $color_theme-2; } } }

        p {
            font-size: 16px;
            font-weight: 500;
            color: #a7a7a7; }

        .rbtn {
            font-size: 16px;
            color: #c5c5c5;
            font-weight: 700;
            position: relative;
            display: inline-block;
            line-height: 16px;

            &:before {
                position: absolute;
                content: '';
                width: 100%;
                bottom: 0;
                height: 1px;
                background: #383838; }

            i {
                margin-left: 5px; }

            &:hover {
                color: $color-theme-2;

                &:before {
                    background: $color-theme-2; } } } } }

/* Recent Post */
.recent-news-inner {
    margin-top: 50px; }

.recent-news {
    .blog-title {
        color: #dedede;
        font-size: 18px;
        line-height: 26px;
        margin-top: 15px;
        font-weight: 600; }

    .meta {
        a {
            color: #888888;

            &.admin {
                &:after {
                    background: #888; } }

            &:hover {
                color: $color_theme-2; } } } }

/* Weekly Top News */
#weekly-top {
    padding-top: 60px; }

.cat-two {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;

        a {
            color: #888;
            text-transform: uppercase;

            &:hover {
                color: $color_theme-2; } } } }

.top-news {
    margin-bottom: 30px;

    img {
        max-width: 100%; }

    .cat-two {
        margin-top: 5px; }

    .blog-title {
        font-size: 18px;
        line-height: 25px; } }

.top-mews-feature {
    border: 1px solid #1f1f31;

    .feature-image {
        position: relative;

        img {
            max-width: 100%;
            height: auto; }

        .cat {
            position: absolute;
            left: 20px;
            bottom: 20px; } }

    .blog-content {
        padding: 20px;

        .blog-title {
            font-size: 30px;
            line-height: 40px;
            margin: 0; } } }

/* Latest News */
#latest-news {
    padding: 60px 0; }

.latest-post {
    .blog-content {
        position: absolute;
        left: 0;
        bottom: 20px;
        width: 100%;
        padding: 0 20px;

        .blog-title {
            margin-bottom: 10px; }

        .content {
            line-height: 25px;
            font-size: 16px;
            margin-bottom: 0; } }

    &.blog-small {
        .blog-content {
            text-align: center;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            transition: all 0.3s ease-in-out;
            padding: 10px;
            position: absolute;
            top: 0;
            left: 0;

            .content {
                position: absolute;
                bottom: 10px;
                transition: all 0.3s ease-in-out;
                height: 100%;
                width: 100%;
                top: 132%;
                left: 0;
                transform: translateY(-50%);

                .blog-title {
                    font-size: 18px;
                    margin: 0 0 10px 0;
                    transition: all 0.4s ease-in-out;
                    text-align: center; }

                .read-more {
                    font-size: 14px;
                    line-height: 25px;
                    font-weight: 600;
                    background: #FFF;
                    padding: 3px 11px;
                    color: #444;
                    transition: all 0.2s ease-in-out;
                    display: inline-block;

                    &:hover {
                        color: #fff; } } } }

        &:hover {

            .blog-content {
                background: rgba($color_theme-2 ,0.9);

                .content {
                    top: 85%;

                    .blog-title {
                        color: darken($color_theme-2, 10%); } } } } }


    &.blog-mid {
        .blog-content {
            .blog-title {
                font-size: 20px;
                line-height: 25px; }

            .content {
                margin-bottom: 0;
                font-size: 14px;
                line-height: 20px; } } } }

/* Upcoming Game */
.blog-post-two {
    &.upc-game {
        margin-bottom: 30px;
        .blog-content {
            .blog-title {
                margin-bottom: 5px; }
            p {
                color: #FFF;
                font-size: 16px;
                line-height: 25px; } } } }

.related-game-inner {
    .related-game {
        .game-meta {
            list-style: none;
            li {
                margin-right: 10px;
                position: relative;
                display: inline-block;

                a {
                    color: #FFF;
                    font-size: 12px;
                    line-height: 25px;
                    font-weight: 500;

                    &:hover {
                        color: $color_theme-2; } }

                &:after {
                    content: '/';
                    right: -9px;
                    top: 0;
                    position: absolute;
                    color: #FFF;
                    font-size: 10px; }

                &:last-child {
                    &:after {
                        display: none; } } } }

        .game-title {
            font-size: 16px;
            line-height: 25px;
            margin-bottom: 0;
            a {
                color: #FFF;

                font-weight: 600;

                &:hover {
                    color: $color_theme-2; } } } } }

.games-video-slider-inner {
    margin: 50px 0; }

.upc-game-items {
    border: 1px solid #1f1f31;
    padding: 10px 0;

    .item:not(:first-child) {
        margin-top: 10px; }

    .item:not(:last-child) {
        padding-bottom: 10px;
        border-bottom: 1px solid #1f1f31; }

    .item {
        padding-left: 10px;
        padding-right: 10px;

        .feature-image {
            float: left; }

        .content {
            margin-left: 175px;

            .blog-title {
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 15px;
                margin-top: 0;
                font-weight: 500; }

            .read-btn {
                background-color: transparent;
                color: #fff;
                font-size: 14px;
                padding: 0;
                border: 0;

                i {
                    margin-left: 4px;
                    font-size: 8px;
                    transition: all 0.3s ease-in-out;
                    vertical-align: middle; }

                &:hover {
                    color: $color_theme-2;

                    i {
                        margin-left: 6px; } } } } } }

.review-wrap {
    position: absolute;
    left: 10px;
    bottom: 5px;
    .review-count {
        font-size: 14px;
        margin: 0;
        color: #ffeb3b;
        font-weight: 700; }

    .review {
        line-height: 18px;
        li {
            color: #ffeb3b; } } }

.gallery-thumbs {
    .swiper-slide {
        &.swiper-slide-active {
            border: 2px solid $color_theme-2; } } }

.latest-post {
    &.stategy {
        .blog-content {
            padding: 20px;
            left: 0;
            bottom: 0;

            .blog-title {
                font-size: 25px;
                line-height: 30px; }

            .content {
                font-size: 14px;
                line-height: 22px;
                margin-top: 5px; } } } }

/* Upcoming Game */
#upcoming-game {
    padding-top: 60px; }


/* Upcoming Game Two*/
#upcoming-game-two, #gameing-post-three {
    padding: 100px 0;
    background-attachment: fixed;
    background-size: cover; }

.upc-game-two {
    background-color: #0f0f1b;
    padding: 20px 30px;
    border-radius: 10px;
    margin-bottom: 20px;

    .feature-image {
        height: 140px;
        width: 140px;
        border-radius: 50%;
        overflow: hidden;
        float: left;

        a {
            display: block;

            img {
                border-radius: 50%; } } }

    .game-content {
        margin-left: 160px;

        h3 {
            color: #fff;
            font-size: 22px;
            font-weight: 700;
            line-height: 40px;
            margin-bottom: 0;

            a {
                color: #fff;

                &:hover {
                    color: $color_theme-2; } } }

        p {
            color: #cecece;
            font-weight: 500;
            margin-bottom: 10px;
            line-height: 26px; }

        .meta-three {
            a {
                font-size: 14px;
                font-weight: 500;
                color: #fff;
                margin-right: 10px;
                margin-right: 10px;

                i {
                    margin-right: 5px; }

                &.rd-btn {
                    color: $color_theme-2;
                    text-decoration: underline; } } } } }

/* Game Blog Post */
#game-post {
    padding: 100px 0;
    background: #13131f; }

.latest-post {
    &.game-blog-post {
        border-radius: 5px;
        overflow: hidden;
        max-width: 500px;
        .blog-content {
            .blog-title {
                line-height: 20px;
                margin: 0;

                a {
                    font-size: 20px;

                    font-weight: 700;

                    &:hover {
                        color: $color_theme-2; } }

                a {
                    line-height: 20px;
                    font-size: 20px;
                    font-weight: 700; } }

            .review {

                li {
                    font-size: 13px;
                    line-height: 13px;
                    color: $color_theme-2; } }

            .meta {
                li {
                    margin-right: 0;

                    a {
                        &:hover {
                            color: $color_theme-2; } } } }

            .follow-btn {
                position: absolute;
                right: 20px;
                bottom: 20px; } } } }

.game-large-player {
    .youtube-wrapper {
        border-radius: 5px;
        padding-bottom: 55.5%;
        .youtube-poster {
            height: 405px; }

        &:before {
            font-size: 100px; } } }

.sidebar-blog {
    background: #111;
    padding: 10px 10px 20px 10px;
    border-radius: 5px;
    max-width: 500px;
    .feature-image {
        float: left;

        img {
            max-width: 100%;
            border-radius: 5px;
            overflow: hidden; } }

    .blog-content {
        margin-left: 120px;

        .blog-title {
            font-size: 18px;
            margin: 10px 0 0;

            a {
                &:hover {
                    color: $color_theme-2; } } }

        .review {
            li {
                color: $color_theme-2;
                font-size: 13px; } }

        .meta {
            li {
                margin-right: 0;
                font-size: 14px;
                color: $color_theme-2;

                a {
                    &:hover {
                        color: $color_theme-2; } } } }

        .follow-btn {
            margin-top: 5px; } } }

/* Gaming Post */
.gaming-post {
    .feature-image {
        border-radius: 5px;
        position: relative;

        .meta {
            li {
                position: relative;

                &:after {
                    position: absolute;
                    content: '';
                    right: -5px;
                    top: 7px;
                    height: 14px;
                    width: 2px;
                    background: #d2c6bf; }

                &:last-child {
                    &:after {
                        display: none; } }

                a {
                    color: #fff;
                    font-size: 16px;

                    i {
                        color: $color_theme-2; }

                    &:hover {
                        color: $color_theme-2; } } } }



        .blog-content {
            position: absolute;
            left: 20px;
            bottom: 10px;
            width: 90%;

            .blog-title {
                font-size: 18px;
                line-height: 25px;
                font-weight: 700;
                color: #fff;
                margin-bottom: 5px;

                a {
                    color: #fff;

                    &:hover {
                        color: $color_theme-2; } } }

            p {
                margin-bottom: 0;
                font-size: 16px;
                line-height: 22px;
                color: #fff; } } } }

/* Blog Post Clane */
#latest-news-clan-two {
    background-size: cover; }

.clan-blog-post {
    padding: 15px;
    background: #1a1c20;
    border-radius: 7px;

    img {
        max-width: 100%; }

    .content {
        margin-top: 25px;
        h3 {
            font-size: 20px;
            line-height: 28px;
            font-weight: 600;
            margin: 0;

            a {
                color: #fff;

                &:hover {
                    color: $color_theme-2; } } }

        .meta {
            &.meta-grey {
                li {
                    position: relative;
                    margin-right: 5px;

                    &:after {
                        content: '.';
                        position: absolute;
                        right: -4px;
                        bottom: 0;
                        font-size: 32px;
                        color: #4f4f4f;
                        line-height: 37px; }

                    &:last-child {
                        &:after {
                            display: none; } }

                    a {
                        font-size: 14px; } } } }

        p {
            color: #b2b2b2;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 12px;
            margin-top: 10px; } } }

.clan-blog-post-list {
    padding: 15px;
    background: #1a1c20;
    border-radius: 7px;

    .clan-blog-list {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #292929;

        a {
            img {
               border-radius: 10px; } }

        .content {
            margin-left: 100px;

            h4 {
                font-size: 14px;
                line-height: 22px;
                margin: 0;

                a {
                    color: #fff;

                    &:hover {
                        color: $color_theme-2; } } } }

        .meta {
            li {
                a {
                    font-size: 14px; } } } }

    .game-video-item {
        border-radius: 10px; } }

.author-about {
    .avatar {
        float: left;
        position: relative;
        z-index: 5;
        padding: 15px;

        img {
            z-index: 5;
            height: 75px;
            width: 75px;
            border-radius: 50%; }


        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            background-image: url("../../media/blog/49.png");
            height: 105px;
            width: 105px;
            z-index: -1; } }

    .content {
        margin-left: 130px;

        .author-name {
            font-size: 20px;
            color: #fff;
            font-weight: 600;
            margin: 0; }

        .social-link {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                display: inline-block;
                margin-right: 10px;

                a {
                    color: #fff;
                    font-size: 14px; } } }

        p {
            font-size: 14px;
            line-height: 25px;
            color: #fff;
            margin-bottom: 0; } } }

.comments-wrap {
    .comments {
        margin: 0;
        padding: 0;
        list-style: none;

        .comments-body {
            .comments-inner {
                background: #090808;
                padding: 20px;
                border-radius: 10px;
                margin-bottom: 20px;

                .avatar {
                    float: left;

                    img {
                        border-radius: 50%; } }

                .avatar-comments {
                    margin-left: 90px;
                    p {
                        margin: 0; } }

                .comments-author {
                    display: inline-block;
                    position: relative;

                    a {
                        color: #dedede;
                        font-size: 16px;
                        font-weight: 700;
                        &:hover {
                            color: $color_theme-4; } } }
                .avatar-meta {
                    margin: 0 15px;
                    display: inline-block;
                    color: #858585;
                    font-size: 16px;
                    font-weight: 500;
                    position: relative;

                    &:hover {
                        color: $color_theme-4;

                        &:before, &:after {
                            color: #858585; } }

                    &:after, &:before {
                        position: absolute;
                        content: '|';
                        top: 1px; }

                    &:before {
                        left: -10px; }

                    &:after {
                        right: -10px; } }

                .reply {
                    color: $color_theme-4;
                    font-size: 16px;
                    font-weight: 500; } } }

        .reply-comment {
            margin-left: 50px; } }

    .title {
        color: #fff;
        font-size: 25px;
        font-weight: 500;
        margin: 0 0 20px; } }

.comments-form {
    .form-comments {
        textarea, input {
            width: 100%;
            background: #000;
            outline: 0;
            border: 0;
            color: #fff;

            &::placeholder {
                color: #fff; } }
        textarea {
            height: 200px;
            padding: 10px 20px;
            border-radius: 5px;
            margin-bottom: 20px; }

        input {
            width: 50%;
            float: left;
            padding: 6px 20px;
            margin-bottom: 20px;
            height: 45px;


            &.br {
                border-right: 1px solid #2d2d2d;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px; }

            &.bdr {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px; } }

        .tim-btn-bg {
            cursor: pointer;
            padding: 16px 59px;
            margin-bottom: 20px;
            display: inline-block;
            background: #ea1d25;

            &:hover {
                background: $color_theme-3; } } } }

/* Ganing Post */
#gameing-post-three {
    background: #13131f; }


/* Blog Grid */
.blog-post-grid {
    padding-bottom: 20px;
    background: #1b1b1b;
    border-radius: 5px;
    margin-bottom: 30px;
    .featire-image {
        overflow: hidden;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        img {
            max-width: 100%;
            transition: all 0.3s ease-in-out; }


        &:hover {
            img {
                transform: scale(1.1) rotate(3deg); } } }
    .post-type-icon {
        height: 55px;
        width: 55px;
        text-align: center;
        display: block;
        position: relative;
        z-index: 111;
        font-size: 24px;
        font-weight: 700;
        color: #fff;
        line-height: 55px;
        transform: translate(30px, -27px);
        position: absolute;

        span {
            display: block;
            font-size: 16px; }

        &:before {
            position: absolute;
            content: '';
            height: 55px;
            width: 55px;
            background: $color_theme-2;
            left: 0;
            top: 0;
            transform: rotate(45deg);
            border-radius: 4px;
            z-index: -1; }

        &:after {
            position: absolute;
            content: '';
            border: 2px solid #fff;
            height: 100%;
            width: 100%;
            border-radius: 4px;
            left: 0;
            top: 0;
            transform: rotate(-45deg); } }

    .blog-content {
        padding: 50px 25px 0;

        .blog-title {
            font-size: 20px;
            line-height: 25px;
            margin: 15px 0;

            a {
                color: #dedede;

                &:hover {
                    color: $color_theme-2; } } }

        p {
            font-size: 16px;
            color: #dedede;
            line-height: 24px;
            margin-top: 10px; }

        .tim-btn {
            font-size: 11px;
            line-height: 15px;
            padding: 5px 12px;
            border-radius: 3px;

            &:hover {
                color: #fff; } } } }

.blog-meta {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
        margin-right: 5px;
        color: #dedede;
        font-size: 14px;

        i {
            margin-right: 7px;
            color: $color_theme-2; }

        .avatar {
            margin-right: 10px; }

        .date {
            color: #dedede;

            &:hover {
                color: $color_theme-4; } }

        a {
            font-size: 14px;
            color: #dedede;
            font-weight: 500;

            &:hover {
                color: $color_theme-2; } } } }

.blog-grid {
    background: #131313; }

.blog-grid-two-column {
    background: #000; }


.blog-grid-four {
    background-attachment: fixed; }

.blog-masonry {
    background: #131313;
    padding: 100px 0; }

// Single Post
.single-post {
    padding: 50px 0;
    background: #131313; }

.blog-details {

    .meta {
        margin-bottom: 20px; }

    p {
        color: #b1b1b1;
        margin-bottom: 30px;
        font-size: 16px;
        line-height: 30px; }

    .youtube-wrapper {
        margin-bottom: 30px;

        &:before {
            font-size: 80px; }

        .youtube-poster {
            height: 500px; } }

    h3 {
        font-size: 20px;
        font-weight: 700;
        margin: 0 0 10px;
        color: #fff; }

    blockquote {
        padding: 35px 30px;
        background: #1f1f1f;
        color: #fff;
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        border-left: 6px solid $color_theme-2;
        margin-bottom: 20px;
        position: relative; }

    .blog-inner-thumb {
        margin-bottom: 30px;

        img {
            max-width: 100%;
            height: auto; } } }

.tagCloud {
    a {
        display: inline-block;
        padding: 0 10px;
        color: #e9e9e9;
        background: #0d0b0b;
        font-size: 14px;
        line-height: 30px;

        &:hover {
            background: $color_theme-2; } } }

.share {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;

    li {
        display: inline-block;
        position: relative;
        transition: all 0.3s ease-in-out;
        margin-right: 5px;

        a {
            color: #fff;
            display: block;
            height: 40px;
            width: 40px;
            text-align: center;
            line-height: 44px;
            border-radius: 50%;

            &.fb_bg {
                background: #3b5998; }

            &.tw_bg {
                background: #1da1f2; }

            &.go_bg {
                background: #ea4335; } }

        .like-count {
            display: block;
            position: absolute;
            height: 35px;
            width: 60px;
            background: #fff;
            left: 50%;
            top: -47px;
            transform: translateX(-50%);
            padding: 5px;
            text-align: center;
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s ease-in-out;
            color: #303030;

            &:after {
                content: '';
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid #fff;
                position: absolute;
                bottom: -10px;
                left: 50%;
                transform: translateX(-50%); } }

        &:hover {
            .like-count {
                opacity: 1;
                visibility: visible; } } } }

.author-about-inner {
    margin-top: 40px;
    border-bottom: 1px solid #252525; }

.blog-comments-inner, .author-about-inner {
    background: #060505;
    padding: 50px;


    .commtent-title {
        color: #fff;
        font-size: 25px;
        line-height: 28px;
        margin-bottom: 20px;
        font-weight: 500; }


    .comments-body {
        p {
            color: #fff; } }

    .comments-form {

        input[type="text"], textarea {
            background: #000;

            &::placeholder {
                color: #929292; } } } }

#related-post {
    position: relative;
    background: #131313;
    padding: 0 0 50px;

    .title {
        color: #fff;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 40px; }

    .slider-nav-prev, .slider-nav-next {
        &:before {
            background: #ec4e00; } } }

.full-width {
    max-width: 970px;
    width: 100%;
    margin: 0 auto;

    .blog-details {
        .youtube-wrapper {
            .youtube-poster {
                height: 580px; } } } }

// Post Nav
.nav-links {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    margin-top: 70px;

    li {
        display: inline-block;
        text-align: center;
        margin-right: 10px;

        a {
            display: block;
            height: 30px;
            width: 30px;
            background: #232323;
            color: #fff;
            line-height: 30px;
            border-radius: 4px;

            &:hover, &.active {
                background: $color_theme-2; } }

        &:nth-child(2) {
            position: absolute;
            bottom: -40px; }

        &:nth-child(4) {
            position: absolute;
            top: -40px;
            left: 43px; } } }

@media (min-width: 992px) and (max-width: 1200px) {
    .upc-game-items {
        .item {
            .feature-image {
                max-width: 130px; }

            .content {
                margin-left: 140px;

                .blog-title {
                    font-size: 14px;
                    margin-top: 0; }

                .cat {
                    li {
                        a {
                            padding: 0 13px; } } } } } } }

@media (max-width: 1024px) {
    .blog-content {
        .blog-title {
            font-size: 22px;
            margin: 10px 0;
            z-index: 333; }

        .content {
            font-size: 14px;
            line-height: 22px; } }

    .cat {
        li {
            a {
                padding: 0 7px; } } }

    .upc-game-items {
        .item {
            .content {
                .blog-title {
                    font-size: 16px; } } } }

    .recent-news {
        .blog-title {
            font-size: 22px;
            margin: 10px 0; } }

    .blog-details {
        padding: 0 20px; }

    .clan-blog-post-list {
        margin-bottom: 30px;

        .clan-blog-list {
            .meta {
                li {
                    a {
                        font-size: 13px; } } } }

        .content {
            margin-left: 90px;

            h3 {
                font-size: 18px; } } } }

@media (max-width: 991px) {
    .blog-post-three {
        margin-bottom: 20px; }

    .gaming-post {
        max-width: 570px;
        margin: 0 auto 30px; }

    .gaming-post-inner {
        max-width: 600px;
        margin: 0 auto; }

    .blog-post {
        margin-bottom: 30px; }

    .latest-post {
        &.game-blog-post {
            max-width: 370px; } }

    .game-large-player {
        .youtube-wrapper {
            margin-bottom: 30px; } }

    .blog-post-two, .latest-post {
        &.blog-large {
            margin-bottom: 30px; } }

    .recent-news {
        margin-bottom: 30px; }

    .upc-game-items {
        margin-bottom: 30px; } }

@media (max-width: 768px) {

    #blog-two, #gameing-post-three {
        padding: 50px 0; }

    .blog-post {
        max-width: 370px;
        margin: 0 auto 30px; }

    .blog-post-three {
        max-width: 450px;
        margin: 0 auto 30px; }

    .blog-post-grid {
        max-width: 450px;
        margin: 0 auto 30px; }

    .blog-content {
        .blog-title {
            font-size: 20px;
            margin: 10px 0;
            z-index: 333; } }

    .blog-post-two.blog-large {
        .feature-image {
            .blog-content {
                .blog-title {
                    font-size: 25px;
                    line-height: 30px; } } } }

    .latest-post {
        &.blog-large, &.blog-mid {
            margin-bottom: 20px; }

        &.blog-small:hover {
            .blog-content {
                .content {
                    .read-more {
                        margin-top: 180px; } } } } }

    .related-game {
        margin-bottom: 20px; }

    .blog-post-two {
        &.blog-large {
            margin-bottom: 10px; } }

    .top-mews-feature {
        margin-bottom: 20px; }

    .upc-game-items {
        margin-bottom: 20px; }


    // Bolg Details
    .blog-details {
        .blog-title {
            font-size: 25px; } }

    .blog-inner-thumb {
        .pr__15 {
            padding-right: 0 !important;
            margin-bottom: 15px; } }

    .blog-details {
        p {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 15px; }

        blockquote {
            font-size: 18px;
            line-height: 26px; } }

    .game-large-player {
        .youtube-wrapper {
            &:before {
                font-size: 65px; } } }

    .tagCloud {
        a {
            font-size: 14px;
            padding: 5px 8px; } }

    // Comments
    .blog-comments-inner, .author-about-inner {
        padding: 25px; }

    .latest-post {
        &.game-blog-post {
            .blog-content {
                .blog-title {
                    a {
                        font-size: 16px; } }

                .follow-btn {
                    bottom: 28px; } } } }

    .review-wrap {
        .review {
            li {
                font-size: 12px; } } } }

@media (max-width: 500px) {

    .blog-post-two {
        .feature-image {
            .blog-content {
                bottom: 0; } } }

    .blog-post-two {
        &.blog-large {
            .feature-image {
                .blog-content {
                    .blog-title {
                        margin: 0; } } } } }

    .blog-post-two.blog-large {
        .feature-image {
            .blog-content {
                .blog-title {
                    font-size: 20px;
                    line-height: 25px; } } } }
    .cat {
        li {
            a {
                padding: 0 8px;
                font-size: 13px; } } }

    .upc-game-items {
        .item {
            .content {
                .blog-title {
                    font-size: 14px;
                    line-height: 20px; } } } }

    .review-wrap {
        .review {
            li {
                font-size: 12px; } } }

    .recent-news {
        .blog-title {
            font-size: 20px;
            line-height: 25px;
            margin-top: 10px; } }

    .top-mews-feature {
        margin-bottom: 20px;
        .blog-content {
            .blog-title {
                font-size: 25px;
                line-height: 25px; } } }

    .comments-wrap {
        .comments {
            .reply-comment {
                margin-left: 20px; } } }

    .comments-wrap {
        .comments {
            .comments-body {
                .comments-inner {
                    padding: 15px;

                    .avatar-comments {
                        margin-left: 80px; }

                    .comments-author {
                        margin-bottom: 0;
                        a {
                            font-size: 14px; } }
                    .avatar-meta {
                        font-size: 14px;
                        margin: 0 10px; } } } } }

    .author-about {
        .content {
            margin-left: 115px;

            p {
                line-height: 20px; } } }

    .blog-comments-inner, .author-about-inner {
        p {
            font-size: 14px;
            line-height: 20px; } }

    .gaming-post {
        .feature-image {
            .blog-content {
                .blog-title {
                    font-size: 16px;
                    line-height: 18px; } } } }

    .upc-game-two {
        .feature-image {
            float: none;
            margin: 0 auto; }

        .game-content {
            margin-left: 0;
            margin-top: 20px;
            text-align: center;

            h3 {
                font-size: 20px; } } }

    .latest-post.game-blog-post {
        .blog-content {
            .follow-btn {
                bottom: 25px; } } }


    .author-about {
        text-align: center;

        .avatar {
            float: none;
            width: 105px;
            margin: 0 auto 15px; }

        .content {
            margin-left: 0; } }

    .comments-wrap {
        .comments {
            .comments-body {
                .comments-inner {
                    text-align: center;

                    .avatar {
                        float: none;
                        margin: 0 auto 15px; }

                    .avatar-comments {
                        margin-left: 0; } } } } }

    .section-title-two {
        .title {
            &:before, &:after {
                display: none; } } } }


@media (max-width: 400px) {
    .upc-game-items {
        .item {
            text-align: center;

            .feature-image {
                float: none; }

            .content {
                margin-left: 0;
                margin-top: 20px;
                text-align: center; } } } }
