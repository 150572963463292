.col-to-action-game {
    padding: 40px 100px;
    background: rgba(0,0,0,0.502);

    .title-inner {
        width: 85%;
        float: left;

        h2 {
            font-size: 40px;
            line-height: 40px;
            font-weight: 700;
            color: #fff; }
        h3 {
            font-size: 30px;
            font-weight: 500;
            color: #fff;
            margin-bottom: 0; } }

    .button-inner {
        width: 15%;
        padding: 19px 0;
        float: right;

        .tim-btn-bg {
            padding: 14px 32px;
            text-transform: capitalize;

            &:hover {
                background: $color_theme-5; } } } }

@media (max-width: 992px) {
    .col-to-action-game {
        padding: 40px 50px;

        .title-inner {
            width: 80%; }

        .button-inner {
            width: 20%; } } }

@media (max-width: 768px) {
    .col-to-action-game {
        padding: 40px 50px;
        text-align: center;

        .title-inner {
            width: 100%; }

        .button-inner {
            width: 100%;
            text-align: center;
            padding-bottom: 0; } } }


@media (max-width: 768px) {
    .col-to-action-game {
        padding: 30px;
        .title-inner {

            h2 {
                font-size: 30px; }

            h3 {
                font-size: 25px; } } } }

