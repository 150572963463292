#video {
    padding: 250px 0;
    text-align: center;
    position: relative;
    background-position: center center;
    background-size: cover; }

.video-btn {
    height: 85px;
    width: 85px;
    border: 10px solid #f68b1f;
    text-align: center;
    line-height: 84px;
    display: inline-block;
    border-radius: 50%;
    padding-left: 10px;
    position: relative;
    outline: 0;
    background: #fff;

    &:after {
        position: absolute;
        content: '';
        height: 161%;
        width: 161%;
        top: -20px;
        border: 10px solid rgba(246,139,41, 0.5);
        left: -20px;
        border-radius: 50%; }


    i {
        font-size: 35px;
        color: #f68b1f; } }

#video-two {
    padding: 200px 0 60px;
    text-align: center;
    background-size: cover;
    background-position: center center; }

.play-button {
    margin-bottom: 30px;
    i {
        font-size: 117px;
        color: #fff;
        margin-bottom: 20px; }

    h3 {
        color: #fff;
        font-weight: 700;
        font-size: 30px; } }

.meta-inner {
    .meta {
        margin-bottom: 20px;
        li {
            font-size: 20px;
            font-weight: 700;
            color: #fff; } }

    .tim-btn-bg {
        padding: 14px 42px;
        font-size: 20px;
        display: inline-block; } }


@media (max-width: 1024px) {
    #video {
        padding: 150px 0; } }

@media (max-width: 768px) {
    #video-two {
        padding: 100px 0 50px; }

    .play-button {
        i {
            font-size: 75px; } } }
