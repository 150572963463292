#purchase {
    background-size: cover;
    background-position: center center;
    padding: 100px 0 70px; }

.game-info {
    margin-bottom: 20px;
    p {
        font-weight: 600;
        font-size: 18px;
        margin: 0;

        span {
            font-weight: 400;
            font-size: 16px; } } }

.purhase-game-inner {
    > p {

        font-size: 16px;
        line-height: 25px;
        margin-bottom: 30px; }

    .tim-btn {
        font-size: 20px;
        line-height: 20px;
        padding: 14px 42px;
        vertical-align: middle;
        margin-top: 20px;

        &:after {
            background: $color_theme-2; }

        &:hover {
            color: #fff;
            border-color: $color_theme-2; } } }
