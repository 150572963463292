#tournaments {
    padding: 100px 0;
    background: #13131f;
 }    // background: #141416

.trurnament-inner {
    background: #1a1c20;
    border-top: 5px solid $color_theme-2;
    padding-top: 20px;

    .torurmant {
        margin: 0 auto;
        padding: 20px 15px;
        transition: all 0.3s ease-in-out;
        border-bottom: 1px solid #303030;

        .game-item {
            display: inline-block;
            width: 33.33%;
            float: left;

            img {
                height: 70px;
                width: 70px;
                border-radius: 5px; }

            h4 {
                color: #fff;
                font-weight: 700;
                font-size: 16px;
                margin-top: 10px;
                margin-bottom: 0;

                a {
                    color: #fff;

                    &:hover {
                        color: $color_theme-2; } } }

            &.vs {
                text-align: center;

                h4 {
                    font-size: 18px;
                    color: #fff;
                    font-weight: 600;
                    height: 45px;
                    width: 45px;
                    background: $color_theme-2;
                    border-radius: 50%;
                    line-height: 47px;
                    margin: 20px auto;
                    position: relative;
                    z-index: 2;

                    &:before, &:after {
                        position: absolute;
                        content: '';
                        border-radius: 50%;
                        z-index: -1; }

                    &:before {
                        left: -5px;
                        top: -5px;
                        bottom: -5px;
                        right: -5px;
                        border: 5px solid rgba(255,255,255,0.2); }

                    &:after {
                        left: -10px;
                        top: -10px;
                        bottom: -10px;
                        right: -10px;
                        border: 5px solid rgba(255,255,255,0.1); } } } } }

    .tournaments-details {
        padding: 20px 25px;


        .date {
            width: 33.33%;
            float: left;
            display: inline-block;
            text-align: center;

            h4 {
                color: #fff;
                font-size: 18px;
                font-weight: 500;
                line-height: 24px; } }

        .details {
            display: inline-block;
            float: left;

            .review {
                margin-bottom: 10px;

                li {
                    color: $color_theme-2;
                    font-size: 14px; } }

            a {
                color: #fff;
                font-size: 16px;


                &.tim-btn {
                    padding: 10px 12px;
                    line-height: 15px;
                    text-transform: capitalize;
                    font-weight: 500;
                    border-radius: 4px; } }

            .meta {
                color: #fff;

                &:hover {
                    color: $color_theme-2; } } } }

    &:hover {
       .torurmant {
            background-image: none; } } }

@media (min-width: 992px) and (max-width: 1200px) {
    .trurnament-inner {
        .torurmant {
            .game-item {
                img {
                    height: 55px;
                    width: 55px; }

                h4 {
                    font-size: 14px;
                    font-weight: 600; }

                &.vs {
                    h4 {
                        margin: 15px auto; } } } } } }

@media (max-width: 991px) {
    .trurnament-inner {
        margin-bottom: 20px;
        max-width: 350px;
        margin: 0 auto 20px; } }
