$font-family-retro: my_girl_is_retroregular, sans-serif;
$font-family-primary: 'Roboto Condensed', sans-serif;


$color_theme: #D82F2B;
$color_theme-2: #f68b1f;
$color_theme-3: #fd0e0e;
$color_theme-4: #ffc107;
$color_theme-5: #487b00;
$color_theme-6: #d52b31;
$color_theme-7: #00d2ff;
$color_gradian: radial-gradient(ellipse farthest-corner at center 0px,#11383a -300%,#262635 45%,#27273a 85%);

